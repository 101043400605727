<template>
  <div class="family-tree-page-container">
    <family-tree-landing-page v-if="showLanding"></family-tree-landing-page>
    <template v-else>
      <div class="tree-actions top-left" v-if="showSelector">
        <family-tree-selector
          :list="familyTreeListState"
          :tree-id="routeTreeId"
          :tree-name="familyTreeNameState"
          v-tooltip="treeFindTooltip"
          @select="onTreeSelect"
        ></family-tree-selector>
        <div class="tree-action item" v-tooltip="homeTooltip" @click="goHome" v-if="isRouteFT">
          <home-icon title="" :size="24"></home-icon>
        </div>
        <family-tree-shortcuts-button
          v-if="isRouteFT && isDesktop && isUserOwnerFamilyTreeState"
          class="tree-action item"
        ></family-tree-shortcuts-button>
      </div>
      <div class="tree-actions top-right" v-if="displayControls && familyTreeDetailsIdState">
        <familytree-sharing-popover
          :tree-id="familyTreeDetailsIdState"
          :tree-name="familyTreeNameState"
          :is-tree-public="isFamilyTreePublicState"
          :tree-members="familyTreeMembersState"
          :is-owner="isUserOwnerFamilyTreeState"
          class="tree-action item"
          @members-change="onShareMembersChange"
          @is-public-change="onSharePublicChange"
        ></familytree-sharing-popover>
        <family-tree-search-button ref="search" class="tree-action item"></family-tree-search-button>
        <router-link
          v-if="showSettingsButton"
          class="tree-action item"
          :to="manageTreeRoute"
          v-tooltip="{content: 'Manage Tree', placement: 'bottom-end'}"
        >
          <cog-icon :size="24" title=""></cog-icon>
        </router-link>
      </div>
      <div class="tree-actions bottom-right" v-if="displayControls && familyTreeDetailsIdState && isDesktop">
        <family-tree-lineage-switch class="tree-action group"></family-tree-lineage-switch>
        <cards-language-switch class="tree-action group"></cards-language-switch>
        <family-tree-zoom
          class="tree-action group"
          :class="{disabled: !isRouteFT}"
          @zoom-in="zoomIn"
          @zoom-out="zoomOut"
        ></family-tree-zoom>
      </div>
      <div class="tree-actions bottom-right" v-if="displayControls && familyTreeDetailsIdState && !isDesktop">
        <family-tree-mobile-controls-button class="tree-action item"></family-tree-mobile-controls-button>
      </div>

      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" ref="page"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" ref="page"></router-view>

      <person-photos-gallery-overlay-container></person-photos-gallery-overlay-container>
    </template>
  </div>
</template>

<script>
import {keyF} from '@common/utils/consts.keycodes';
import CogIcon from 'vue-material-design-icons/Cog';
import HomeIcon from 'vue-material-design-icons/HomeOutline';
import {mapGetters} from 'vuex';

import FamilyTreeSelector from '@/components/common/inputs/familyTreeSelector';

import {CARD_FULL_PROFILE_HELPER_KEY, QUICK_VIEW_HELPER_KEY} from '@/components/modules/familyTree/constants';
import CardsLanguageSwitch from '@/components/modules/familyTree/controls/cardsLanguageSwitch';
import FamilyTreeLineageSwitch from '@/components/modules/familyTree/controls/familyTreeLineageSwitch';
import FamilyTreeMobileControlsButton from '@/components/modules/familyTree/controls/familyTreeMobileControlsButton';
import FamilyTreeShortcutsButton from '@/components/modules/familyTree/controls/familyTreeShortcutsButton.vue';
import FamilyTreeZoom from '@/components/modules/familyTree/controls/familyTreeZoom';
import PersonPhotosGalleryOverlayContainer from '@/components/modules/familyTree/tree/gallery/personPhotosGalleryOverlayContainer';

import FamilyTreeSearchButton from './controls/familyTreeSearchButton';
import FamilytreeSharingPopover from './controls/familytreeSharingPopover';
import FamilyTreeLandingPage from './familytree.landing.page';
import OnboardingDoneModalContent from './tree/modals/OnboardingDoneModalContent';

export default {
  created() {
    document.addEventListener('keydown', this.onKeyDown);
    this.$store.dispatch('fetchFamilyTreeListAction');

    if (this.$store.getters.familyTreeIsMiniMapVisibleState == null) {
      this.$store.commit(
        'setFamilyTreeIsMiniMapVisibleState',
        this.$store.getters.windowWidthState > this.$breakpoints.mobile
      );
    }
    if (this.$store.getters.treeOnboardingSuccessMessageState) {
      let modalParams = {
        classes: 'clear_modal white_modal',
        class: 'mobile_bottom',
        name: 'onboarding-done',
        clickToClose: false,
      };
      let props = {hasRelatives: this.$store.getters.treeOnboardingSuccessMessageState.hasRelatives};
      const modalEvents = {
        closed: () => {
          if (!this.$store.getters.reviewedItemsState.includes(QUICK_VIEW_HELPER_KEY)) {
            this.$store.commit('setShowCardQuickViewTooltipState', true);
          }
          if (!this.$store.getters.reviewedItemsState.includes(CARD_FULL_PROFILE_HELPER_KEY)) {
            this.$store.commit('setShowCardFullProfileTooltipState', true);
          }
        },
      };
      this.$modal.show(OnboardingDoneModalContent, props, modalParams, modalEvents);
      this.$store.commit('setTreeOnboardingSuccessMessageState', null);
    } else {
      if (!this.$store.getters.reviewedItemsState.includes(QUICK_VIEW_HELPER_KEY)) {
        this.$store.commit('setShowCardQuickViewTooltipState', true);
      }
      if (!this.$store.getters.reviewedItemsState.includes(CARD_FULL_PROFILE_HELPER_KEY)) {
        this.$store.commit('setShowCardFullProfileTooltipState', true);
      }
    }
  },
  destroyed() {
    document.removeEventListener('keydown', this.onKeyDown);
  },
  computed: {
    ...mapGetters([
      'userIsLoggedInState',
      'userIsSharedState',
      'familyTreeDetailsIdState',
      'isFamilyTreePublicState',
      'familyTreeMembersState',
      'familyTreeNameState',
      'familyTreeStartPersonState',
      'isUserOwnerFamilyTreeState',
      'familyTreeListState',
      'featureSwitchesState',
      'showFindOwnedTreeTooltipState',
    ]),
    showLanding() {
      return (!this.userIsLoggedInState || this.userIsSharedState) && !this.$route.params.id;
    },
    showSettingsButton() {
      return this.familyTreeListState.find(tree => tree.object_id === this.routeTreeId);
    },
    isDesktop() {
      return this.$store.getters.windowWidthState >= this.$breakpoints.mobile;
    },
    isRouteFT() {
      return this.$route.name === 'familytree-details-my' || this.$route.name === 'familytree-details';
    },
    homeTooltip() {
      return {
        content: 'Back to root',
        placement: 'bottom',
      };
    },
    displayControls() {
      return this.$route.meta.displayControls;
    },
    showSelector() {
      if (!this.displayControls) {
        return false;
      }
      if (this.familyTreeListState.find(tree => tree.object_id === this.routeTreeId)) {
        return true;
      }
      return this.familyTreeDetailsIdState && this.familyTreeNameState;
    },
    manageTreeRoute() {
      return {name: 'familytree-manage', params: {id: this.familyTreeDetailsIdState}};
    },
    routeTreeId() {
      return parseInt(this.$route.params.id) || this.familyTreeDetailsIdState;
    },
    treeFindTooltip() {
      return {
        content: 'You can switch to your\nown tree here.',
        popperClass: 'text-center in-background',
        placement: 'bottom',
        triggers: [],
        shown: this.showFindOwnedTreeTooltipState,
      };
    },
  },
  methods: {
    onKeyDown(e) {
      const isRouteSearchable = this.$route.name === 'familytree-details' || this.$route.name === 'familytree-lineage';
      if (this.familyTreeDetailsIdState && (e.ctrlKey || e.metaKey) && e.which === keyF && isRouteSearchable) {
        e.preventDefault();
        this.$refs.search.openSearchPanel();
      }
    },
    zoomIn() {
      this.$refs.page.$refs['family-tree'].zoomInToCenter();
    },
    zoomOut() {
      this.$refs.page.$refs['family-tree'].zoomOutToCenter();
    },
    centerPerson(personId) {
      this.$refs.page.$refs['family-tree'].shiftTreeToFocusPerson(personId);
    },
    goHome() {
      if (this.$route.query.start_person_id) {
        this.$router.push({name: 'familytree-details', params: {id: this.familyTreeDetailsIdState}});
        return;
      }
      this.centerPerson(this.familyTreeStartPersonState.object_id);
    },
    onTreeSelect(value) {
      this.$router.push({name: 'familytree-details', params: {id: value.object_id}});
    },
    onShareMembersChange(newList) {
      this.$store.commit('setFamilyTreeMembersState', newList);
    },
    onSharePublicChange(value) {
      this.$store.commit('setFamilyTreeIsPublicState', value);
    },
  },
  components: {
    FamilyTreeShortcutsButton,
    FamilyTreeMobileControlsButton,
    CardsLanguageSwitch,
    FamilyTreeZoom,
    FamilyTreeLineageSwitch,
    FamilyTreeSearchButton,
    FamilytreeSharingPopover,
    FamilyTreeLandingPage,
    PersonPhotosGalleryOverlayContainer,
    FamilyTreeSelector,
    HomeIcon,
    CogIcon,
  },
};
</script>

<style lang="scss" scoped>
$offset-desktop: 20px;
$offset-mobile: 12px;

.tree-actions {
  position: fixed;
  z-index: 4;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  &.top-left {
    left: $offset-desktop;
    top: calc(#{$main-menu-height} + #{$offset-desktop});
    display: flex;
    z-index: 5;

    .tree-action.item {
      margin-left: 8px;
      padding: 5px;
      cursor: pointer;
    }
  }

  &.top-right {
    display: flex;
    right: $offset-desktop;
    top: calc(#{$main-menu-height} + #{$offset-desktop});

    .tree-action + .tree-action {
      margin-left: 8px;
    }

    .tree-action.item {
      padding: 5px 8px;
      cursor: pointer;
    }
  }

  &.bottom-right {
    display: flex;
    flex-direction: column;
    right: $offset-desktop;
    bottom: $offset-desktop;

    .tree-action + .tree-action {
      margin-top: 8px;
    }
  }

  &.bottom-left {
    display: flex;
    flex-direction: column;
    left: $offset-desktop;
    bottom: $offset-desktop;

    .tree-action.item {
      cursor: pointer;
    }
  }

  &::v-deep {
    .tree-action {
      border-radius: 4px;
      border: 1px solid $border-color-light;
      box-shadow: $box-shadow-extra-light;
      background: $background-alternate-color;
      color: $text-color;
      display: flex;
      align-items: center;
      justify-content: center;

      .label {
        margin-left: 3px;
        margin-right: 1px;
      }
    }
    .tree-action.disabled {
      pointer-events: none;
      opacity: 0.45;
    }
    .tree-action.group {
      flex-direction: column;
      .item {
        cursor: pointer;
        display: flex;
        padding: 8px;
        color: $text-color;
      }
      .item.is-active {
        color: $link-color;
      }
      .item + .item {
        border-top: 1px solid $border-color-light;
      }

      &.is-unavailable {
        cursor: not-allowed;
        background-color: $mcr-light-grey;
      }
    }

    .family-tree-selector {
      width: 200px;
      .family-tree-multiselect {
        max-width: 380px;

        &.multiselect--active {
          position: absolute;
          width: 380px;
          max-width: 380px;
        }
      }
    }
  }

  @media only screen and (max-width: $main-menu-breakpoint) {
    &.top-left {
      left: $offset-mobile;
      top: calc(#{$main-menu-height-mobile} + #{$offset-mobile});
    }
    &.top-right {
      right: $offset-mobile;
      top: calc(#{$main-menu-height-mobile} + #{$offset-mobile});
    }
    &.bottom-right {
      right: $offset-mobile;
      bottom: $offset-mobile;
    }
    &.bottom-left {
      left: $offset-mobile;
      bottom: $offset-mobile;
    }

    &::v-deep {
      .tree-action.item {
        padding: 8px;

        .label {
          display: none;
        }
      }

      .family-tree-selector {
        width: fit-content;

        .family-tree-multiselect {
          max-width: calc(100vw - 220px);

          &.multiselect--active {
            position: absolute;
            width: calc(100vw - 20px);
            max-width: calc(100vw - 20px);
          }
        }
      }
    }
  }
}

.family-tree-page-container.has-xmas-banner {
  .tree-actions.top-left,
  .tree-actions.top-right {
    top: calc(#{$main-menu-height} + #{$offset-desktop} + 76px);
    @media only screen and (max-width: $main-menu-breakpoint) {
      top: calc(#{$main-menu-height-mobile} + #{$offset-mobile} + 104px);
    }
  }
}

.family-tree-page-container::v-deep .full_view {
  height: calc(100vh - #{$main-menu-height});

  @media only screen and (max-width: $main-menu-breakpoint) {
    height: calc(100vh - #{$main-menu-height-mobile});
  }
}

.family-tree-page-container.has-xmas-banner::v-deep .full_view {
  height: calc(100vh - #{$main-menu-height} - 76px);

  @media only screen and (max-width: $main-menu-breakpoint) {
    height: calc(100vh - #{$main-menu-height-mobile} - 104px);
  }
}
</style>
