<template>
  <div class="lineage-card">
    <div class="generation-number">{{ generationNumber }}</div>

    <div class="data-wrapper" @click="dataWrapperClick">
      <img class="image" :src="imageSrc" v-if="imageSrc" />
      <person-icon-colored class="image" :gender="gender" v-else></person-icon-colored>

      <div class="title">
        <div class="name">{{ fullNameDisplay }}</div>
        <div class="dates">{{ datesDisplay }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {getFullNameByLangMixed} from '@common/utils/utils.names';

import PersonIconColored from '@/base/icons/person/PersonIconColored';

import {UNKNOWN_NAME} from '@/components/modules/familyTree/constants';

export default {
  props: {
    imageSrc: String,
    gender: String,
    fullName: String,
    firstNames: Array,
    surnames: Array,
    birthDate: Object,
    deathDate: Object,
    isDeceased: Boolean,
    generationNumber: Number,
  },
  computed: {
    datesDisplay() {
      let birth = this.getDateDisplay(this.birthDate) || 'Unknown';
      let death = this.getDateDisplay(this.deathDate) || (this.isDeceased ? 'Deceased' : 'Living');
      return `${birth} - ${death}`;
    },
    fullNameDisplay() {
      if (this.firstNames || this.surnames) {
        return (
          getFullNameByLangMixed(this.firstNames, this.surnames, this.$store.getters.familyTreeIsLangChineseState) ||
          UNKNOWN_NAME
        );
      }
      return this.fullName || UNKNOWN_NAME;
    },
  },
  methods: {
    getDateDisplay(date) {
      let year = date ? date.year : '';
      return year && date.approximation_years ? `ca. ${year}` : year;
    },
    dataWrapperClick() {
      this.$emit('click-data');
    },
  },
  components: {PersonIconColored},
};
</script>

<style lang="scss" scoped>
$border-radius: 5px;

.lineage-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border: 1px solid $divider-line-color;
  border-radius: $border-radius;
  border-bottom-color: #dadada;
  border-right: 0;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.05);
  position: relative;
  width: 100%;

  .generation-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    background: #ece9e7;
    height: 100%;
    border-radius: $border-radius;
    color: #b7b7b7;
    flex-shrink: 0;
  }

  .data-wrapper {
    display: flex;
    background: $background-alternate-color;
    flex-grow: 1;
    border-radius: $border-radius;
    overflow: hidden;
    border: 1px solid #dadada;

    .image {
      height: 60px;
      width: 60px;
      border-right: 1px solid #dadada;
      &::v-deep svg {
        height: 45px;
      }
    }

    .title {
      margin-left: 18px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
      max-width: calc(100% - 80px);
    }
    .name {
      max-width: calc(100% - 12px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .dates {
      color: $supplemental-text-color;
      font-size: 0.9em;
    }

    &:hover {
      border: 2px solid $power-red;
      cursor: pointer;
      margin: -1px;
    }
  }
}
</style>
