<template>
  <div class="familytree-card-container">
    <div class="menu-container">
      <div class="card-tooltip-wrapper" :style="cardTooltipWrapperStyle" v-if="treeCardTooltipText">
        <div class="card-tooltip-wrapper-bounce bounce" v-if="treeCardTooltipText">
          <div class="card-tooltip">{{ treeCardTooltipText }}</div>
          <div class="card-tooltip-arrow-down"></div>
        </div>
      </div>

      <v-dropdown
        :popperClass="menuPopperClass"
        ref="dropdown"
        :autoHide="false"
        :placement="menuPlacement"
        :skidding="menuSkidding"
        :shown="showMenu"
        :triggers="[]"
        container=".card-menu-container"
        :boundary="menuBoundary"
        handle-resize
      >
        <slot
          name="card"
          :immediateHideMenu="immediateHideMenu"
          :showMenu="showMenu"
          :handlePersonClick="onClick"
          :openContextMenu="openContextMenu"
          :mouseenter="mouseenter"
          :mouseleave="mouseleave"
        >
          <family-tree-card
            ref="card"
            :is-hovered="isHoveredState"
            :person="person"
            :has-hints="personHasHints"
            :color-code-gender="familyTreePreferencesState.color_code_gender"
            @click-card="onClick"
            @dblclick-card="openEditPersonQuickSidebar"
            @alt-click-card="onAltClick"
            @ctrl-click-card="onCtrlClick"
            @contextmenu="openContextMenu"
            @mouseenter="mouseenter"
            @mouseleave="mouseleave"
          ></family-tree-card>
        </slot>

        <template v-slot:popper>
          <quick-view-menu
            v-if="shownMenuUI === QUICK_VIEW_MENU"
            :is-active="showMenu && shownMenuUI === QUICK_VIEW_MENU"
            :person="person"
            :is-edit-allowed="isEditAllowed"
            :removable="removable"
            :hints-count="$store.getters.familyTreeHintsCountsByPersonIdsState[person.id] || 0"
            :can-add-parents="!personHasBothParents"
            :relatives-loading="familyTreePersonRelativesLoadingState"
            @close="closeQuickView"
            @click-show-hints="showHintsHandler(person)"
            @click-full-profile="goToPersonProfilePage(person)"
            @click-media="goToPersonProfileMediaPage(person)"
            @click-view-tree="clickViewTree(person)"
            @click-view-lineage="clickViewLineage(person)"
            @click-quick-edit="onClickEdit(person)"
            @click-delete="handleDeleteClick(person)"
            @click-add-parent="addParentContextMenuHandler(person)"
            @click-add-spouse="addSpouseContextMenuHandler(person)"
            @click-add-sibling="addSiblingContextMenuHandler(person)"
            @click-add-child="addChildContextMenuHandler(person)"
          ></quick-view-menu>
          <family-tree-card-menu
            v-else-if="shownMenuUI === RIGHT_CLICK_MENU"
            :sections="visibleContextMenuSections"
            :show-add-relative-submenu="showAddRelativeSubmenu"
            :relatives-loading="familyTreePersonRelativesLoadingState"
            :can-add-parents="!personHasBothParents"
            @click-full-profile="goToPersonProfilePage(person)"
            @click-edit="onClickEdit(person)"
            @click-full-profile-edit="onClickFullProfileEdit(person)"
            @click-view-tree="clickViewTree(person)"
            @click-view-lineage="clickViewLineage(person)"
            @click-delete="handleDeleteClick(person)"
            @click-add-relative="handleAddRelativeClick"
            @click-add-parent="addParentContextMenuHandler(person)"
            @click-add-spouse="addSpouseContextMenuHandler(person)"
            @click-add-sibling="addSiblingContextMenuHandler(person)"
            @click-add-child="addChildContextMenuHandler(person)"
            @mouseenter.native="mouseenter"
            @mouseleave.native="mouseleave"
          >
          </family-tree-card-menu>
        </template>
      </v-dropdown>
    </div>
  </div>
</template>

<script>
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import {mapGetters} from 'vuex';

import {
  CONTEXT_MENU_SECTIONS,
  HEIGHT,
  QUICK_EDIT_HELPER_KEY,
  QUICK_VIEW_HELPER_KEY,
  RELATION_TYPE_NAMES,
  UNKNOWN_NAME,
} from '@/components/modules/familyTree/constants';
import FamilyTreeCard from '@/components/modules/familyTree/tree/familyTreeCard';
import FamilyTreeCardMenu from '@/components/modules/familyTree/tree/familyTreeCardMenu';
import personDeleteConfirmModalContent from '@/components/modules/familyTree/tree/modals/personDeleteConfirmModalContent';
import personDeleteFailedModalContent from '@/components/modules/familyTree/tree/modals/personDeleteFailedModalContent';
import personDetailsModalContent from '@/components/modules/familyTree/tree/modals/personDetailsModalContent';
import PersonQuickCreate from '@/components/modules/familyTree/tree/modals/quick/PersonQuickCreate';
import PersonQuickEdit from '@/components/modules/familyTree/tree/modals/quick/PersonQuickEdit';
import PersonQuickView from '@/components/modules/familyTree/tree/modals/quick/PersonQuickView';
import QuickViewMenu from '@/components/modules/familyTree/tree/modals/quick/QuickViewMenu';
import {
  createRelativeHandler,
  getNewPersonGender,
  getNewPersonGenerationNumberByRelationType,
  getNewPersonHierarchyLevelByRelationType,
  getNewPersonSurnameByRelationType,
  getPossibleChildrenList,
  getPossibleParentsList,
} from '@/components/modules/familyTree/tree/services';
import {
  closeQuickSidebar,
  openAddPersonModal,
  openQuickSidebar,
} from '@/components/modules/familyTree/tree/services.modals';

import {PARENT_RELATION_TYPE, SIBLING_RELATION_TYPE, SPOUSE_RELATION_TYPE, CHILD_RELATION_TYPE} from '../constants';

const SECTIONS = Object.values(CONTEXT_MENU_SECTIONS);
const QUICK_VIEW_MENU = 'QUICK_VIEW_MENU';
const RIGHT_CLICK_MENU = 'RIGHT_CLICK_MENU';

export default {
  props: {
    person: Object,
    handleClickAllowed: Boolean,
    scale: {type: Number, default: 1},
    contextMenuSections: {
      type: Array,
      validator: value => value.every(item => SECTIONS.includes(item)),
      default: () => SECTIONS,
    },
    cardHeight: {type: Number, default: HEIGHT},
    quickActionsEnabled: {type: Boolean, default: true},
    isDetailed: {type: Boolean, default: true},
    viewTreeAction: {type: Function, required: false},
  },
  data() {
    return {
      showAddRelativeSubmenu: false,
      showMenu: false,
      shownMenuUI: null,
      cardHovering: false,
      showMenuTimeout: null,
      QUICK_VIEW_MENU,
      RIGHT_CLICK_MENU,
    };
  },
  watch: {
    treeCardMenuShownPersonIdState(value) {
      if (value && value !== this.person.object_id) {
        this.immediateHideMenu();
      }
    },
  },
  computed: {
    ...mapGetters([
      'familyTreeDetailsIdState',
      'familyTreePersonRelativesLoadingState',
      'isFamilyTreeWriteAllowedState',
      'quickSidebarPropsState',
      'familyTreeFocusPersonIdState',
      'familyTreePreferencesState',
      'showCardQuickEditTooltipState',
      'showCardQuickViewTooltipState',
      'treeCardMenuShownPersonIdState',
    ]),
    personParents() {
      return this.$store.getters.getFamilyTreePersonRelativesState(this.person.object_id).parents || [];
    },
    personParentsLength() {
      return this.personParents.length;
    },
    personHasBothParents() {
      return this.personParentsLength === 2;
    },
    menuShowingAllowed() {
      return this.$store.getters.windowWidthState > this.$breakpoints.mobile;
    },
    visibleContextMenuSections() {
      let sections = [...this.contextMenuSections];
      const indexDelete = sections.indexOf(CONTEXT_MENU_SECTIONS.DELETE);
      if (
        indexDelete > -1 &&
        (!this.isFamilyTreeWriteAllowedState || this.person.is_current_user || this.isHomePerson)
      ) {
        sections.splice(indexDelete, 1);
      }
      const indexEdit = sections.indexOf(CONTEXT_MENU_SECTIONS.EDIT);
      if (indexEdit > -1 && !this.isFamilyTreeWriteAllowedState) {
        sections.splice(indexEdit, 1);
      }
      const indexEditFull = sections.indexOf(CONTEXT_MENU_SECTIONS.FULL_PROFILE_EDIT);
      if (indexEditFull > -1 && !this.isFamilyTreeWriteAllowedState) {
        sections.splice(indexEditFull, 1);
      }
      const indexAddRelative = sections.indexOf(CONTEXT_MENU_SECTIONS.ADD_RELATIVE);
      if (indexEdit > -1 && !this.isFamilyTreeWriteAllowedState) {
        sections.splice(indexAddRelative, 1);
      }
      return sections;
    },
    personHasHints() {
      return Boolean(this.$store.getters.familyTreeHintsCountsByPersonIdsState[this.person.id]);
    },
    isRouteLineage() {
      return this.$route.name === 'familytree-lineage';
    },
    isHoveredState() {
      if (this.showMenu) {
        return true;
      }
      if (this.quickSidebarPropsState.personId) {
        return this.quickSidebarPropsState.personId === this.person.id;
      }
      return this.familyTreeFocusPersonIdState == this.person.id;
    },
    isHomePerson() {
      return this.person.object_id === this.$store.getters.familyTreeHomePerson.id;
    },
    cardTooltipWrapperStyle() {
      const transformedScaleReverse = 1 / this.scale;

      const mapping = {
        0.3: -50,
        0.35: -46,
        0.4: -40,
        0.45: -30,
        0.55: -25,
        0.6: -20,
        0.7: -10,
        0.8: -7,
        0.9: -2,
        1: 0,
        1.1: 0,
        1.2: 3,
        2: 5,
      };
      const scaleFixed = this.scale.toFixed(2);
      let marginTop = 0;
      const keys = sortBy(Object.keys(mapping), k => parseFloat(k));
      for (let scale of keys) {
        if (parseFloat(scaleFixed) <= parseFloat(scale)) {
          marginTop = mapping[scale];
          break;
        }
      }
      return {
        left: '50%',
        marginTop: `${marginTop}px`,
        transform: `translate(-50%,-100%) scale(${transformedScaleReverse})`,
      };
    },
    treeCardTooltipText() {
      if (this.$route.name !== 'familytree-details' || !this.isHomePerson) {
        return null;
      }
      if (this.showCardQuickEditTooltipState && this.isEditAllowed) {
        return this.menuShowingAllowed ? 'Double-click to edit' : 'Double-tap to edit';
      }
      if (this.showCardQuickViewTooltipState) {
        return this.menuShowingAllowed ? 'Click to view' : 'Tap to view';
      }
    },
    isEditAllowed() {
      return this.visibleContextMenuSections.includes(CONTEXT_MENU_SECTIONS.EDIT);
    },
    removable() {
      return this.visibleContextMenuSections.includes(CONTEXT_MENU_SECTIONS.DELETE);
    },
    menuBoundary() {
      return document.querySelector('.family-tree-wrapper');
    },
    menuPlacement() {
      if (!this.quickActionsEnabled) {
        return 'bottom';
      }
      return this.shownMenuUI === RIGHT_CLICK_MENU ? 'right' : 'right-end';
    },
    menuSkidding() {
      return this.shownMenuUI === RIGHT_CLICK_MENU ? 0 : 20;
    },
    menuPopperClass() {
      let classes = 'under-main-menu';
      if (this.shownMenuUI === RIGHT_CLICK_MENU) {
        classes += ' main-menu-dropdown';
      }
      return classes;
    },
  },
  methods: {
    openContextMenu() {
      this.cardHovering = true;
      this.changeShowMenu(RIGHT_CLICK_MENU);
    },
    mouseenter() {
      this.cardHovering = true;

      if (this.menuShowingAllowed && !this.showMenu) {
        this.showMenuTimeout = setTimeout(() => {
          this.changeShowMenu(RIGHT_CLICK_MENU);
        }, 800);
      }
    },
    mouseleave() {
      this.cardHovering = false;

      if (this.menuShowingAllowed) {
        clearTimeout(this.showMenuTimeout);
        setTimeout(() => {
          if (!this.cardHovering && this.shownMenuUI === RIGHT_CLICK_MENU) {
            this.changeShowMenu(false);
          }
        }, 500);
      }
    },
    immediateHideMenu() {
      clearTimeout(this.showMenuTimeout);
      this.changeShowMenu(false);
    },
    changeShowMenu(value) {
      if (!this.isDetailed) {
        return;
      }
      if (this.menuShowingAllowed || !value) {
        this.showMenu = !!value;
        if (value) {
          this.shownMenuUI = value;
          this.$store.commit('setTreeCardMenuShownPersonIdState', this.person.object_id);
        }

        if (!value) {
          setTimeout(() => {
            this.showAddRelativeSubmenu = false;
          }, 200);
        }
      }
    },
    getDetailsModalName() {
      return `person-details`;
    },
    getEditModalName() {
      return `person-edit`;
    },
    getDeleteModalName() {
      return `person-delete`;
    },
    getDeleteFailedModalName() {
      return `person-delete-failed`;
    },
    getCreateModalName() {
      return 'person-create';
    },
    clickViewTree(person) {
      if (this.viewTreeAction) {
        return this.viewTreeAction(this.familyTreeDetailsIdState, person.object_id);
      }
      const query = {start_person_id: person.object_id};
      this.$router.push({name: 'familytree-details', params: {id: this.familyTreeDetailsIdState}, query});
    },
    clickViewLineage(person) {
      const query = {start_person_id: person.object_id};
      this.$router.push({name: 'familytree-lineage', params: {id: this.familyTreeDetailsIdState}, query});
    },
    reFetchLineage() {
      const params = {id: this.$route.params.id, start_person_id: this.$route.query.start_person_id};
      this.$store.dispatch('fetchFamilyTreeLineageAction', params);
    },
    detailsModalOpened(personId) {
      return () => {
        this.$store.commit('setGalleryCurrentIndexState', null);
        this.$store.dispatch('fetchFamilyTreePersonAction', personId);
        if (isEmpty(this.$store.getters.getFamilyTreePersonRelativesState(personId))) {
          this.$store.dispatch('fetchFamilyTreePersonRelativesAction', personId);
        }
        this.$store.dispatch('fetchFamilyTreePersonPhotosAction', personId);
        this.$store.dispatch('fetchFamilyTreePersonSavedMentionsAction', {person_id: personId});
      };
    },
    editModalOpened(personId) {
      return () => {
        let person = this.$store.getters.familyTreePersonState;
        if (!person || person.object_id.toString() !== personId.toString()) {
          this.$store.dispatch('fetchFamilyTreePersonAction', personId);
        }
        if (isEmpty(this.$store.getters.familyTreePersonOptionsState)) {
          this.$store.dispatch('fetchFamilyTreePersonOptionsAction');
        }
      };
    },
    editModalBeforeClose(person) {
      this.$store.commit('setFamilyTreePersonUpdateErrorsState', null);
      this.openFullViewPersonModal(person);
    },
    modalBeforeOpen() {
      this.$store.commit('setFamilyTreePersonState', null);
      closeQuickSidebar(this);
    },
    relativeClickHandler(relativeId) {
      this.$modal.hide(this.getDetailsModalName());
      setTimeout(() => {
        // since the modals have the same name, have to wait until open
        this.openFullViewPersonModal({id: relativeId, object_id: relativeId});
      }, 100);
    },
    handleAddRelativeClick() {
      if (isEmpty(this.$store.getters.getFamilyTreePersonRelativesState(this.person.object_id))) {
        this.$store.dispatch('fetchFamilyTreePersonRelativesAction', this.person.object_id);
      }
      this.showAddRelativeSubmenu = !this.showAddRelativeSubmenu;
    },
    addParentHandler(person) {
      return this.openAddPersonModal(null, PARENT_RELATION_TYPE, person);
    },
    addSpouseHandler(person) {
      return this.openAddPersonModal(null, SPOUSE_RELATION_TYPE, person);
    },
    addSiblingHandler(person) {
      return this.openAddPersonModal(null, SIBLING_RELATION_TYPE, person);
    },
    addChildHandler(person) {
      return this.openAddPersonModal(null, CHILD_RELATION_TYPE, person);
    },
    addParentContextMenuHandler(person) {
      this.immediateHideMenu();
      if (this.quickActionsEnabled) {
        return this.openAddPersonQuickSidebar(PARENT_RELATION_TYPE, person);
      }
      return this.addParentHandler(person);
    },
    addSpouseContextMenuHandler(person) {
      this.immediateHideMenu();
      if (this.quickActionsEnabled) {
        return this.openAddPersonQuickSidebar(SPOUSE_RELATION_TYPE, person);
      }
      return this.addSpouseHandler(person);
    },
    addSiblingContextMenuHandler(person) {
      this.immediateHideMenu();
      if (this.quickActionsEnabled) {
        return this.openAddPersonQuickSidebar(SIBLING_RELATION_TYPE, person);
      }
      return this.addSiblingHandler(person);
    },
    addChildContextMenuHandler(person) {
      this.immediateHideMenu();
      if (this.quickActionsEnabled) {
        return this.openAddPersonQuickSidebar(CHILD_RELATION_TYPE, person);
      }
      return this.addChildHandler(person);
    },
    onClickEdit(person) {
      this.immediateHideMenu();
      if (this.quickActionsEnabled) {
        return this.openEditPersonQuickSidebar(person);
      }
      return this.goToPersonProfilePage(person, true);
    },
    onClickFullProfileEdit(person) {
      return this.goToPersonProfilePage(person, true);
    },
    onClick(person) {
      this.immediateHideMenu();
      if (this.quickActionsEnabled && (this.isEditAllowed || this.menuShowingAllowed)) {
        return this.openViewPersonQuickSidebar(person);
      }
      return this.goToPersonProfilePage(person);
    },
    openFullViewPersonModal(person) {
      let events = {
        opened: this.detailsModalOpened(person.object_id),
        'before-open': this.modalBeforeOpen,
      };
      let contextMenuSections = [...this.visibleContextMenuSections];
      const indexFullProfile = contextMenuSections.indexOf(CONTEXT_MENU_SECTIONS.FULL_PROFILE);
      if (indexFullProfile > -1) {
        contextMenuSections.splice(indexFullProfile, 1);
      }
      let props = {
        contextMenuSections: contextMenuSections,
        relativeClickHandler: this.relativeClickHandler,
        editClickHandler: (person, initialData) => this.goToPersonProfilePage(person, true),
        deleteClickHandler: this.handleDeleteClick,
        viewTreeClickHandler: () => {
          this.clickViewTree(person);
        },
        viewLineageClickHandler: () => {
          this.clickViewLineage(person);
        },
        addParentHandler: this.addParentHandler,
        addSpouseHandler: this.addSpouseHandler,
        addSiblingHandler: this.addSiblingHandler,
        addChildHandler: this.addChildHandler,
      };
      let modalParams = {classes: 'clear_modal', scrollable: true, height: 'auto', name: this.getDetailsModalName()};
      this.$modal.show(personDetailsModalContent, props, modalParams, events);
    },
    onAltClick(person) {
      this.goToPersonProfilePage(person, false);
    },
    onCtrlClick(person) {
      const routeData = this.$router.resolve(this.getPersonProfileRoute(person, false));
      window.open(routeData.href, '_blank');
    },
    getPersonProfileRoute(person, editMode = false, focusSection = '') {
      const params = {id: this.familyTreeDetailsIdState, personId: person.object_id};
      const query = {tab: 'details'};
      if (editMode) {
        query['edit'] = 'true';
        query['focus'] = focusSection;
      }
      return {name: 'familytree-profile-details', params, query};
    },
    goToPersonProfilePage(person, editMode = false, focusSection = '') {
      this.immediateHideMenu();
      this.$router.push(this.getPersonProfileRoute(person, editMode, focusSection));
    },
    goToPersonProfileMediaPage(person) {
      this.immediateHideMenu();
      const params = {id: this.familyTreeDetailsIdState, personId: person.object_id};
      const query = {tab: 'library'};
      this.$router.push({name: 'familytree-profile-details', params, query});
    },
    handleDeleteClick(person) {
      let props = {
        personName: person.full_name || 'this person',
        personId: person.object_id,
        deleteFunction: () => {
          this.$store
            .dispatch('deleteFamilyTreePersonAction', person.object_id)
            .then(() => {
              closeQuickSidebar(this);
            })
            .catch(() => {
              let props = {error: this.$store.getters.familyTreePersonDeleteErrorsState};
              let modalParams = {
                classes: 'clear_modal white_modal',
                class: 'mobile_bottom',
                name: this.getDeleteFailedModalName(),
              };
              this.$modal.show(personDeleteFailedModalContent, props, modalParams);
            })
            .finally(() => {
              this.$modal.hide(this.getDeleteModalName());
              this.$modal.hide(this.getDetailsModalName());
            });
        },
      };
      let modalParams = {classes: 'clear_modal white_modal', class: 'mobile_bottom', name: this.getDeleteModalName()};
      this.$modal.show(personDeleteConfirmModalContent, props, modalParams);
    },
    openAddPersonModal(initialData, relationType, person) {
      const treeId = this.$store.getters.familyTreeDetailsIdState;
      const modalName = this.getCreateModalName();
      const openDetails = true;
      const refetchTree = true;
      const callback = formData => {
        this.$modal.hide(modalName);
        this.$modal.hide(this.getDetailsModalName());
        if (this.isRouteLineage && relationType === PARENT_RELATION_TYPE && formData.gender === 'm') {
          this.reFetchLineage();
        }
      };
      openAddPersonModal(
        initialData,
        null,
        relationType,
        person,
        treeId,
        modalName,
        openDetails,
        refetchTree,
        callback,
        this
      );
    },
    openAddPersonQuickSidebar(relationType, person) {
      const possibleParentsList = getPossibleParentsList(relationType, person, this);
      const possibleChildrenList = getPossibleChildrenList(relationType, person, this);
      let childrenIds = possibleChildrenList.filter(option => option.selected).map(option => option.id);
      let parentsIds = possibleParentsList.find(option => option.selected);
      parentsIds = parentsIds ? parentsIds.id : [];
      let surnames = getNewPersonSurnameByRelationType(relationType, person, parentsIds, this);

      const isMobileView = !this.menuShowingAllowed;
      const sidebarProps = {
        id: `${relationType}${person.id}`,
        personId: person.id,
        prefillSurnames: surnames,
        isMobileView: isMobileView,
      };

      const props = {
        relationType: relationType,
        relationTypeName: RELATION_TYPE_NAMES[relationType],
        fullName: person.full_name || UNKNOWN_NAME,
        fromPersonId: person.id,
        initialData: {
          gender: getNewPersonGender(relationType, person, this),
          hierarchy_level: getNewPersonHierarchyLevelByRelationType(person.hierarchy_level, relationType),
          generation_number: getNewPersonGenerationNumberByRelationType(person.generation_number, relationType),
          family_tree_id: this.familyTreeDetailsIdState,
          surnames: surnames,
        },
        possibleChildrenList: possibleChildrenList,
        possibleParentsList: possibleParentsList,
        childrenIdsChange: value => {
          childrenIds = value;
        },
        parentsIdsChange: value => {
          parentsIds = value;
          const newSurnames = getNewPersonSurnameByRelationType(relationType, person, parentsIds, this);
          this.$store.commit('setQuickSidebarPropsState', {...sidebarProps, prefillSurnames: newSurnames});
        },
        saveClickHandler: async formData => {
          await createRelativeHandler(formData, relationType, person, null, childrenIds, parentsIds, false, true, this);
          closeQuickSidebar(this);
        },
        fullFormOpener: formData => {
          closeQuickSidebar(this);
          this.openAddPersonModal(formData, relationType, person);
        },
      };
      let events = {
        opened: () => {
          this.$emit('open-sidebar', person.id);
          this.$store.commit('setFamilyTreeFocusPersonIdState', null);
        },
      };
      openQuickSidebar(this, PersonQuickCreate, sidebarProps, props, events);
    },
    openEditPersonQuickSidebar(person) {
      if (!this.isEditAllowed) {
        return;
      }
      let events = {
        opened: () => {
          this.$emit('open-sidebar', person.id);
          this.$store.commit('addReviewedItemState', QUICK_EDIT_HELPER_KEY);
          this.$store.commit('setShowCardQuickEditTooltipState', false);
          this.$store.commit('setFamilyTreeFocusPersonIdState', null);
          AnalyticsAmplitudeHandler.trackTreeOpenQuickEditEvent(person.id);
        },
      };

      const isMobileView = !this.menuShowingAllowed;
      const sidebarProps = {
        id: `edit${person.id}`,
        personId: person.id,
        isMobileView: isMobileView,
      };
      const props = {
        fullName: person.full_name || UNKNOWN_NAME,
        initialData: person,
        saveClickHandler: async (formData, profilePictureData) => {
          const personPromise = this.$store.dispatch('updateFamilyTreePersonAction', formData);
          const picturePromise = this.updateProfilePicture(profilePictureData, person.id);

          Promise.all([personPromise, picturePromise]).then(responses => {
            closeQuickSidebar(this);
            const action = [
              {
                text: 'View Profile',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0);
                  this.goToPersonProfilePage(person);
                },
              },
            ];
            this.$toasted.success(`Changes saved! `, {action});
          });
        },
        fullFormOpener: formData => {
          AnalyticsAmplitudeHandler.trackTreeEditDetailsEvent(person.id);
          closeQuickSidebar(this);
          this.goToPersonProfilePage(person, true);
        },
      };
      openQuickSidebar(this, PersonQuickEdit, sidebarProps, props, events);
    },
    updateProfilePicture(profilePictureData, personId) {
      if (!profilePictureData.blob) {
        return Promise.resolve();
      }
      return new Promise((resolve, reject) => {
        if (profilePictureData.file) {
          return this.$store
            .dispatch('uploadFamilyTreePersonPhotoAction', {
              personId: personId,
              file: profilePictureData.file,
            })
            .then(res => {
              this.$store
                .dispatch('setFamilyTreePersonProfilePictureAction', {
                  personId: personId,
                  assetId: res.object_id,
                  file: profilePictureData.blob,
                })
                .then(() => {
                  resolve();
                });
            });
        }
        return this.$store
          .dispatch('setFamilyTreePersonProfilePictureAction', {
            personId: personId,
            assetId: profilePictureData.asset_id,
            file: profilePictureData.blob,
          })
          .then(() => {
            resolve();
          });
      });
    },
    openQuickViewDropdown(person) {
      if (isEmpty(this.$store.getters.getFamilyTreePersonRelativesState(person.id))) {
        this.$store.dispatch('fetchFamilyTreePersonRelativesAction', person.id);
      }
      this.$store.commit('addReviewedItemState', QUICK_VIEW_HELPER_KEY);
      this.$store.commit('setShowCardQuickViewTooltipState', false);
      this.$store.dispatch('fetchFamilyTreePersonPhotosAction', person.id);
      this.$store.commit('setFamilyTreeFocusPersonIdState', null);
      AnalyticsAmplitudeHandler.trackTreeOpenQuickViewEvent(person.id);
      this.changeShowMenu(QUICK_VIEW_MENU);
    },
    openViewPersonQuickSidebar(person) {
      if (!this.handleClickAllowed) {
        return;
      }
      if (this.menuShowingAllowed) {
        return this.openQuickViewDropdown(person);
      }

      if (isEmpty(this.$store.getters.getFamilyTreePersonRelativesState(person.id))) {
        this.$store.dispatch('fetchFamilyTreePersonRelativesAction', person.id);
      }
      const isMobileView = !this.menuShowingAllowed;
      let events = {
        opened: () => {
          this.$store.commit('addReviewedItemState', QUICK_VIEW_HELPER_KEY);
          this.$store.commit('setShowCardQuickViewTooltipState', false);
          this.$store.dispatch('fetchFamilyTreePersonPhotosAction', person.id);
          if (!isMobileView) {
            this.$emit('open-sidebar', person.id);
          }
          this.$store.commit('setFamilyTreeFocusPersonIdState', null);
          AnalyticsAmplitudeHandler.trackTreeOpenQuickViewEvent(person.id);
        },
      };

      const sidebarProps = {
        id: `view${person.id}`,
        personId: person.id,
        isMobileView: isMobileView,
      };
      const hintsCount = this.$store.getters.familyTreeHintsCountsByPersonIdsState[person.id] || 0;
      const props = {
        person: person,
        hintsCount: hintsCount,
        addPhotosAllowed: this.isFamilyTreeWriteAllowedState,
        contextMenuSections: this.visibleContextMenuSections,
        fullProfileHandler: () => this.goToPersonProfilePage(person),
        fullProfileEditHandler: () => this.goToPersonProfilePage(person, true),
        fullProfileEditWithFocusHandler: focusSection => this.goToPersonProfilePage(person, true, focusSection),
        quickEditHandler: () => this.openEditPersonQuickSidebar(person),
        viewTreeHandler: () => this.clickViewTree(person),
        viewLineageHandler: () => this.clickViewLineage(person),
        deleteHandler: () => this.handleDeleteClick(person),
        addParentHandler: () => this.openAddPersonQuickSidebar(PARENT_RELATION_TYPE, person),
        addSpouseHandler: () => this.openAddPersonQuickSidebar(SPOUSE_RELATION_TYPE, person),
        addSiblingHandler: () => this.openAddPersonQuickSidebar(SIBLING_RELATION_TYPE, person),
        addChildHandler: () => this.openAddPersonQuickSidebar(CHILD_RELATION_TYPE, person),
        showHintsHandler: () => this.showHintsHandler(person),
        showViewTree: this.visibleContextMenuSections.includes(CONTEXT_MENU_SECTIONS.VIEW_TREE),
        showViewLineage: this.visibleContextMenuSections.includes(CONTEXT_MENU_SECTIONS.VIEW_LINEAGE),
        removable: this.visibleContextMenuSections.includes(CONTEXT_MENU_SECTIONS.DELETE),
      };

      openQuickSidebar(this, PersonQuickView, sidebarProps, props, events);
    },
    closeQuickView() {
      this.immediateHideMenu();
    },
    showHintsHandler(person) {
      this.immediateHideMenu();
      AnalyticsAmplitudeHandler.trackHintClickButtonEvent();
      const route = {
        name: 'familytree-profile-details',
        params: {id: this.familyTreeDetailsIdState, personId: person.id},
        query: {tab: 'hints'},
      };
      this.$router.push(route);
    },
  },
  components: {QuickViewMenu, FamilyTreeCard, FamilyTreeCardMenu},
};
</script>

<style lang="scss" scoped>
.familytree-card-container {
  position: absolute;
}
.menu-container {
  position: relative;
}

.card-tooltip-wrapper {
  position: absolute;
  z-index: 20;
}
.card-tooltip {
  border-radius: 5px;
  white-space: nowrap;
}
.card-tooltip-arrow-down {
  width: 0;
  height: 0;
  margin: -1px auto 0;
}

.bounce {
  animation: verticalBounceIn 0.8s;
  animation-iteration-count: 1;
}

@keyframes verticalBounceIn {
  0% {
    transform: translateY(20px);
    opacity: 1;
  }
  20% {
    transform: translateY(-20px);
  }
  40% {
    transform: scale(1, 0.85) translateY(5px);
  }
  60% {
    transform: translateY(-10px);
  }
  75% {
    transform: scale(1, 0.9) translateY(0px);
  }
  90% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
