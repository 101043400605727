<template>
  <div class="check-circle" v-if="isFilled"><check-circle-icon :size="24"></check-circle-icon></div>
  <div v-else class="progress-circle" :style="circleStyles"></div>
</template>

<script>
import CheckCircleIcon from 'vue-material-design-icons/CheckCircle';

export default {
  props: {
    quarters: {type: Number, default: 0},
  },
  computed: {
    isFilled() {
      return this.quarters === 4;
    },
    circleStyles() {
      const filled = '#15803D';
      const empty = '#D1D5D8';
      const colors = [];
      for (let i = 0; i < 4; i++) {
        colors[i] = this.quarters && i < this.quarters ? filled : empty;
      }
      return {
        'border-color': colors.join(' '),
      };
    },
  },
  name: 'ProgressCircle',
  components: {CheckCircleIcon},
};
</script>

<style lang="scss" scoped>
.progress-circle {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  border-width: 4px;
  border-style: solid;
  transform: rotate(45deg);
  transition: all 0.5s;
}
.check-circle {
  color: $green-600;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
