<template>
  <v-dropdown
    class="family-tree-sharing-popover"
    popperClass="under-main-menu"
    :autoHide="true"
    :offset="10"
    @show="onShow"
  >
    <div class="control-button">
      <share-icon :size="24"></share-icon>
      <span class="label avenir-font-offset-1">Share</span>
    </div>
    <div slot="popper" class="popover-content" v-if="isOwner">
      <div class="text-lg bold">{{ mainTitle }}</div>
      <div class="text-sm subtitle">Invite your family members to collaborate on your family tree project</div>
      <tree-invite-input :tree-id="treeId" @invited="onMembersInvited"></tree-invite-input>
      <div class="users-list" v-if="treeMembers.length">
        <div class="user-item text-sm" v-for="user in treeMembers" :key="user.email">
          <div class="names">
            <div>{{ user.full_name }}</div>
            <div class="email">{{ user.email }}</div>
          </div>

          <tree-member-role-select
            :tree-id="treeId"
            :email="user.email"
            :is-editor="user.is_editor"
            @pre-role-change="onPreRoleChange"
            @deleted="onRoleDeleted"
          ></tree-member-role-select>
        </div>
      </div>
      <div class="privacy-setting">
        <div class="select-container">
          <multiselect
            class="bordered medium-size"
            :value="privacyValue"
            :open-direction="privacyValue.value ? 'bottom' : 'top'"
            placeholder=""
            :searchable="false"
            :internal-search="false"
            :preserveSearch="false"
            :clearOnSelect="false"
            :show-no-options="true"
            :hide-selected="false"
            :options="privacyOptions"
            track-by="value"
            label="label"
            :showLabels="false"
            @select="onPrivacyChange"
          >
          </multiselect>
          <div class="text-xs">{{ privacyLabel }}</div>
        </div>
        <bounce-loading v-if="treeUpdateLoading" class="dark"></bounce-loading>
        <transition name="fade" v-else>
          <tree-share-link v-if="isTreePublic" :tree-id="treeId" :share-link="shareLink"></tree-share-link>
        </transition>
      </div>
    </div>
    <div slot="popper" class="popover-content" v-else>
      <div class="input-label">Share the tree</div>
      <tree-share-link :tree-id="$route.params.id" ref="sharelink" :share-link="shareLink"></tree-share-link>
    </div>
  </v-dropdown>
</template>

<script>
import BounceLoading from '@common/elements/loading-indicators/bounceLoading';
import ShareIcon from 'vue-material-design-icons/Share';
import Multiselect from 'vue-multiselect';
import {mapGetters} from 'vuex';

import TreeInviteInput from '@/components/common/tree/TreeInviteInput';
import TreeMemberRoleSelect from '@/components/common/tree/TreeMemberRoleSelect';
import TreeShareLink from '@/components/common/tree/TreeShareLink';

export default {
  props: {
    treeId: [Number, String],
    treeName: String,
    isTreePublic: Boolean,
    treeMembers: Array,
    isOwner: Boolean,
    shareLink: String,
  },
  computed: {
    ...mapGetters(['familyTreeUpdateLoadingByIdState']),
    mainTitle() {
      return this.treeName ? `Share ${this.treeName}` : 'Share the tree';
    },
    privacyLabel() {
      const publicTree = 'Anyone with the share link can view this tree.';
      const privateTree = 'Only you and the people you invite will be able to see the tree.';
      return this.isTreePublic ? publicTree : privateTree;
    },
    privacyValue() {
      return this.privacyOptions.find(item => item.value === this.isTreePublic);
    },
    privacyOptions() {
      return [
        {value: false, label: 'Private'},
        {value: true, label: 'Public'},
      ];
    },
    treeUpdateLoading() {
      return this.familyTreeUpdateLoadingByIdState[this.treeId];
    },
  },
  methods: {
    onShow() {
      if (!this.isOwner) {
        setTimeout(() => {
          this.$refs.sharelink.$refs.link.click();
        }, 100);
      }
    },
    onMembersInvited({list}) {
      this.$emit('members-change', list);
    },
    onPrivacyChange(value) {
      this.$store
        .dispatch('updateFamilyTreeAction', {id: this.treeId, is_public: value.value})
        .then(response => {
          this.$emit('is-public-change', response.is_public);
          this.$toasted.success('Privacy setting updated!');
        })
        .catch(() => {
          this.$toasted.error('Error updating privacy setting. Changes were not saved.');
        });
    },
    onPreRoleChange({email, is_editor}) {
      const newTreeMembers = this.treeMembers.map(m => (m.email === email ? {...m, is_editor} : m));
      this.$emit('members-change', newTreeMembers);
    },
    onRoleDeleted({email}) {
      const newTreeMembers = this.treeMembers.filter(m => m.email !== email);
      this.$emit('members-change', newTreeMembers);
    },
  },
  components: {BounceLoading, TreeShareLink, ShareIcon, TreeInviteInput, Multiselect, TreeMemberRoleSelect},
  name: 'familytreeSharingPopover',
};
</script>

<style lang="scss" scoped>
.family-tree-sharing-popover {
  .control-button {
    display: flex;
  }
}

.popover-content {
  padding: 20px;
  &::v-deep {
    .subtitle {
      margin: 0 0 15px;
      color: $neutral-500;
    }

    .privacy-setting,
    .users-list {
      border-top: 1px solid $neutral-200;
      padding-top: 16px;
      margin-top: 16px;
    }

    .select-container {
      display: flex;
      align-items: center;

      .multiselect {
        width: 90px;
        flex-shrink: 0;
        margin-right: 8px;
        .multiselect__tags {
          cursor: pointer;
        }
      }

      .text-xs {
        color: $neutral-500;
      }
    }

    .privacy-setting {
      .tree-share-link,
      .bounce-loading {
        margin-top: 10px;
      }
    }

    .users-list {
      .user-item {
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .names {
          margin-right: 10px;
        }

        .email {
          word-break: break-word;
          color: $neutral-500;
        }

        &:first-of-type {
          padding-top: 0;
        }
        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-tablet) {
  .popover-content {
    width: 450px;
  }
}
</style>
