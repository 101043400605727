<template>
  <div class="mobile-actions-modal">
    <div class="action-item">
      <div class="text-lg-mobile item-heading">View style</div>
      <div class="options">
        <div class="option" :class="familyTreeRoute.name === $route.name ? 'is-active' : ''" @click="treeClick">
          <tree-icon :size="24"></tree-icon>
          <div class="text-sm">Tree</div>
        </div>
        <div class="option" :class="lineageRoute.name === $route.name ? 'is-active' : ''" @click="lineageClick">
          <lineage-icon :size="24"></lineage-icon>
          <div class="text-sm">Lineage</div>
        </div>
      </div>
    </div>
    <hr />
    <div class="action-item">
      <div class="text-lg-mobile item-heading">Show names in language</div>
      <div class="options">
        <div class="option" :class="{'is-active': !familyTreeIsLangChineseState}" @click="setEnLanguage">
          <div class="text-lg">Aa</div>
          <div class="text-sm">English</div>
        </div>
        <div class="option" :class="{'is-active': familyTreeIsLangChineseState}" @click="setCnLanguage">
          <chinese-icon :size="24"></chinese-icon>
          <div class="text-sm">Chinese</div>
        </div>
      </div>
    </div>
    <hr />
    <div class="minimap-item" v-if="featureSwitchesState.FAMILY_TREE_MINIMAP">
      <div class="text-lg-mobile item-heading">Show minimap</div>
      <switch-toggle :value="familyTreeIsMiniMapVisibleState" @toggle="switchMiniMap"></switch-toggle>
    </div>
  </div>
</template>

<script>
import SwitchToggle from '@common/elements/inputs/SwitchToggle';
import LineageIcon from 'vue-material-design-icons/FormatListBulletedSquare';
import ChineseIcon from 'vue-material-design-icons/IdeogramCjkVariant';
import TreeIcon from 'vue-material-design-icons/Sitemap';
import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'familyTreeStartPersonIdState',
      'familyTreeDetailsIdState',
      'familyTreeIsLangChineseState',
      'familyTreeIsMiniMapVisibleState',
      'featureSwitchesState',
    ]),
    familyTreeRoute() {
      const query = this.familyTreeStartPersonIdState ? {start_person_id: this.familyTreeStartPersonIdState} : {};
      return {name: 'familytree-details', params: {id: this.familyTreeDetailsIdState}, query};
    },
    lineageRoute() {
      const query = this.familyTreeStartPersonIdState ? {start_person_id: this.familyTreeStartPersonIdState} : {};
      return {name: 'familytree-lineage', params: {id: this.familyTreeDetailsIdState}, query};
    },
  },
  methods: {
    treeClick() {
      this.$emit('close');
      if (this.familyTreeRoute.name !== this.$route.name) {
        this.$router.push(this.familyTreeRoute);
      }
    },
    lineageClick() {
      this.$emit('close');
      if (this.lineageRoute.name !== this.$route.name) {
        this.$router.push(this.lineageRoute);
      }
    },
    setLanguage(value) {
      const toCn = 'Showing Chinese names by default';
      const toEn = 'Showing English names by default';
      const toastText = value ? toCn : toEn;

      this.$store.commit('setFamilyTreeIsLangChineseState', value);
      this.$toasted.success(toastText);
    },
    setEnLanguage() {
      this.$emit('close');
      this.setLanguage(false);
    },
    setCnLanguage() {
      this.$emit('close');
      this.setLanguage(true);
    },
    switchMiniMap() {
      this.$store.commit('setFamilyTreeIsMiniMapVisibleState', !this.familyTreeIsMiniMapVisibleState);
    },
  },
  components: {
    LineageIcon,
    TreeIcon,
    ChineseIcon,
    SwitchToggle,
  },
  name: 'MobileActionsModal',
};
</script>

<style lang="scss" scoped>
.mobile-actions-modal {
  padding: 16px 16px 24px;

  hr {
    border-color: $neutral-200;
    margin: 24px 0 16px;
  }

  .item-heading {
    color: $neutral-600;
    font-weight: 600;
  }

  .action-item {
    .item-heading {
      margin-bottom: 8px;
    }

    .options {
      display: flex;
      column-gap: 20px;

      .option {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 4px;
        border: 1px solid $neutral-300;
        border-radius: 4px;
        font-weight: 500;
        height: 72px;
        width: 88px;

        .text-lg {
          line-height: 24px;
        }

        &.is-active {
          color: $primary-600;
          border-color: $primary-600;
        }
      }
    }
  }

  .minimap-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
