<template>
  <div class="person-create with-sticky-buttons">
    <div class="heading">
      <div class="text-lg bold">Adding {{ relationTypeName }} of {{ personFullName }}</div>
      <close-icon class="close-icon" @click="closeModal"></close-icon>
    </div>
    <mcr-loading-indicator class="content" v-if="loading" :loading="true" />
    <tabs-bar
      v-else
      ref="tabs"
      :start-tab="activeTab"
      @activate-tab="onActivateTab"
      class="bordered"
      :class="{'tabs-content-reverse': activeTab === TAB_EXISTING_PERSON}"
    >
      <template v-slot:common>
        <div v-if="possibleChildrenListCombined.length || possibleParentsListCombined.length" class="extra-section">
          <div v-if="possibleChildrenListCombined.length" class="children-block">
            <div class="label">Include the following individuals as children:</div>
            <multiple-choice-select
              class="text-sm"
              :options="possibleChildrenListCombined"
              :checked="selectedPossibleChildrenIds"
              @change="childrenIdsChange"
            ></multiple-choice-select>
          </div>

          <div v-if="possibleParentsListCombined.length" class="parents-block">
            <div class="label">Parents</div>
            <radio-select
              v-if="possibleParentsListCombined.length"
              :options="possibleParentsListCombined"
              :selected="selectedPossibleParentsIds"
              name="parents-radio"
              class="text-sm"
              @change="parentsIdsChange"
            ></radio-select>
          </div>
        </div>

        <marriage-details-block
          v-if="showMarriageDetails"
          :family-tree-id="initialData.family_tree_id"
          class="extra-section"
          ref="marriageBlock"
        ></marriage-details-block>
      </template>
      <tabs-block-item name="New person" :identifier="TAB_NEW_PERSON">
        <person-full-edit-form class="body" ref="form" :form-initial-data="initialData"></person-full-edit-form>
      </tabs-block-item>
      <tabs-block-item :identifier="TAB_EXISTING_PERSON" name="Person in your tree">
        <existing-person-select-form
          ref="existingperson"
          :relation-type="relationType"
          :tree-id="initialData.family_tree_id"
          :from-person-data="fromPersonData"
          @select="onExistingPersonSelect"
        ></existing-person-select-form>
      </tabs-block-item>
    </tabs-bar>

    <div class="action-buttons right-align">
      <mcr-button class="white bold" :disabled="formLoading" @click="closeModal">Cancel</mcr-button>
      <mcr-button class="save bold" :loading="formLoading" @click="saveClick">Save</mcr-button>
    </div>
  </div>
</template>

<script>
import mcrButton from '@common/elements/buttons/mcrButton';
import RadioSelect from '@common/elements/filters/radioSelect';
import TabsBar from '@common/elements/tabs/TabsBar';
import TabsBlockItem from '@common/elements/tabs/TabsBlockItem';
import isEmpty from 'lodash/isEmpty';
import CloseIcon from 'vue-material-design-icons/Close';
import {mapGetters} from 'vuex';

import MultipleChoiceSelect from '@/base/elements/filters/multipleChoiceSelect';

import PersonFullEditForm from '@/components/common/tree/PersonFullEditForm';

import {UNKNOWN_NAME} from '@/components/modules/familyTree/constants';
import ExistingPersonSelectForm from '@/components/modules/familyTree/tree/modals/ExistingPersonSelectForm.vue';
import MarriageDetailsBlock from '@/components/modules/familyTree/tree/modals/fields/MarriageDetailsBlock';
import {getPossibleChildrenList, getPossibleParentsList} from '@/components/modules/familyTree/tree/services';

const TAB_NEW_PERSON = 'newperson';
const TAB_EXISTING_PERSON = 'existingperson';

export default {
  props: {
    relationType: String,
    relationTypeName: String,
    fullName: String,
    fromPersonId: Number,
    initialData: Object,
    showMarriageDetails: {type: Boolean, default: false},
    saveClickHandler: Function,
    possibleChildrenList: Array,
    possibleParentsList: Array,
    childrenIdsChange: Function,
    parentsIdsChange: Function,
  },
  data() {
    return {
      TAB_NEW_PERSON,
      TAB_EXISTING_PERSON,
      activeTab: TAB_NEW_PERSON,
      possibleChildrenListCombined: [...this.possibleChildrenList],
      possibleParentsListCombined: [...this.possibleParentsList],
    };
  },
  computed: {
    ...mapGetters([
      'familyTreePersonOptionsLoadingState',
      'familyTreePersonOptionsState',
      'familyTreePersonCreateErrorsState',
      'familyTreePersonCreateLoadingState',
      'parenthoodCreateLoadingState',
      'coupleCreateLoadingState',
      'familyTreeDrawLoadingState',
      'familyTreePersonRelativesLoadingState',
    ]),
    loading() {
      return this.familyTreePersonOptionsLoadingState || isEmpty(this.familyTreePersonOptionsState);
    },
    formLoading() {
      return (
        this.familyTreePersonCreateLoadingState ||
        this.parenthoodCreateLoadingState ||
        this.coupleCreateLoadingState ||
        this.familyTreeDrawLoadingState ||
        this.familyTreePersonRelativesLoadingState
      );
    },
    personFullName() {
      return this.fullName || UNKNOWN_NAME;
    },
    selectedPossibleChildrenIds() {
      const selected = this.possibleChildrenListCombined.filter(option => option.selected);
      return selected.length ? selected.map(option => option.id) : [];
    },
    selectedPossibleParentsIds() {
      const selected = this.possibleParentsListCombined.find(option => option.selected);
      return selected ? selected.id : null;
    },
    fromPersonData() {
      return {object_id: this.fromPersonId, full_name: this.fullName};
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    saveClick() {
      const selectedPerson = this.$refs.existingperson.selectedPerson;
      if (this.activeTab === TAB_EXISTING_PERSON) {
        if (!selectedPerson || selectedPerson.object_id === this.fromPersonId) {
          return;
        }
        const marriageData = this.$refs.marriageBlock ? this.$refs.marriageBlock.getDataForSave() : null;
        return this.saveClickHandler(selectedPerson, marriageData);
      }
      const person = this.$refs.form.getModifiedPersonForSave();
      const marriageData = this.$refs.marriageBlock ? this.$refs.marriageBlock.getDataForSave() : null;
      this.saveClickHandler(person, marriageData);
    },
    onActivateTab({tabId}) {
      this.activeTab = tabId;
      this.cleanupTabs();
    },
    cleanupTabs() {
      this.setPossibleChildrenListCombined(getPossibleChildrenList(this.relationType, this.fromPersonData, this));
      this.setPossibleParentsListCombined(getPossibleParentsList(this.relationType, this.fromPersonData, this));
      this.$refs.existingperson.cleanup();
    },
    setPossibleChildrenListCombined(newList) {
      this.possibleChildrenListCombined = newList;
      this.childrenIdsChange(
        this.possibleChildrenListCombined.filter(option => option.selected).map(option => option.id)
      );
    },
    setPossibleParentsListCombined(newList) {
      this.possibleParentsListCombined = newList;
      let parentsIds = this.possibleParentsListCombined.find(option => option.selected);
      this.parentsIdsChange(parentsIds ? parentsIds.id : []);
    },
    onExistingPersonSelect({childrenList, parentsList}) {
      this.setPossibleChildrenListCombined(childrenList);
      this.setPossibleParentsListCombined(parentsList);
    },
  },
  components: {
    ExistingPersonSelectForm,
    TabsBlockItem,
    TabsBar,
    RadioSelect,
    MultipleChoiceSelect,
    PersonFullEditForm,
    CloseIcon,
    mcrButton,
    MarriageDetailsBlock,
  },
  name: 'personCreateModalContent',
};
</script>

<style lang="scss" scoped>
@import '@/components/common/tree/styles/person-create';

.person-create {
  .heading {
    padding-right: 50px;
  }

  .save {
    min-width: 100px;
  }

  .tabs-bar {
    .extra-section {
      border-bottom: 1px solid $neutral-200;
      padding: 8px 24px 16px 24px;
    }
    &.tabs-content-reverse {
      .extra-section {
        border-bottom: none;
        border-top: 1px solid $neutral-200;
        padding: 16px 24px 16px 24px;
      }
    }
  }

  @media only screen and (min-width: $breakpoint-phablet) {
    min-width: 720px;
    width: 720px;
  }
}
</style>
