<template>
  <div class="remove">
    <delete-icon :size="20" v-show="removable" title="Remove"></delete-icon>
    <span class="icon-label">Delete</span>
  </div>
</template>

<script>
import DeleteIcon from 'vue-material-design-icons/Delete';

export default {
  props: {
    removable: Boolean,
  },
  components: {
    DeleteIcon,
  },
  name: 'removeFieldIcon',
};
</script>

<style lang="scss" scoped>
.remove {
  margin-left: 8px;
  opacity: 0.4;
  cursor: pointer;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: left;
  .icon-label {
    display: none;
    margin-left: 4px;
    position: relative;
    top: 1px;
  }
  &:hover {
    opacity: 1;
    color: $link-color;
    &::v-deep .material-design-icon__svg {
      color: $link-color;
    }
  }
  @media only screen and (max-width: $breakpoint-mobile) {
    visibility: visible;
  }

  @media only screen and (max-width: 525px) {
    margin-top: 8px;
    visibility: visible;
    margin-left: 0;
    .icon-label {
      display: block;
    }
  }
}
</style>
