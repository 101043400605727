<template>
  <div class="relative-item">
    <slot name="empty-state"></slot>
    <div class="person-info" v-if="!$slots['empty-state']">
      <div class="profile-picture">
        <img v-if="profilePicture" :src="profilePicture" />
        <person-icon-colored v-else></person-icon-colored>
      </div>
      <div>
        <div class="person-name text-md text-lg-mobile">
          <router-link :to="profileRoute">{{ getName(person) }}</router-link>
        </div>
        <div class="sub-text text-sm">
          <span v-if="years">{{ years }}</span>
          <span v-if="spouseMeta.is_divorced" class="divorced-badge">Divorced</span>
        </div>
      </div>
      <div class="context-menu">
        <v-dropdown :auto-hide="true" class="context-menu-popover">
          <div v-tooltip="{content: 'More Options'}" class="action-wrapper">
            <dots-vertical-icon :size="20" title=""></dots-vertical-icon>
          </div>
          <div slot="popper" class="popover-content">
            <popover-action-button label="View Profile" @click.native="viewProfile(person)">
              <account-icon :size="20" />
            </popover-action-button>
            <popover-action-button label="View in Tree" @click.native="viewInTree(person)">
              <tree-icon :size="20" />
            </popover-action-button>
            <popover-action-button
              v-if="metaEditable"
              label="Edit Relationship"
              @click.native="editRelationship"
              v-close-popper="true"
            >
              <pencil-icon :size="20" />
            </popover-action-button>
            <popover-action-button
              v-if="showAddChild"
              label="Add Child"
              @click.native="addChildClick"
              v-close-popper="true"
            >
              <plus-icon :size="20"></plus-icon>
            </popover-action-button>
            <popover-action-button
              v-if="showChangeParents"
              label="Change Parents"
              @click.native="changeParentsClick"
              v-close-popper="true"
            >
              <swap-icon :size="20"></swap-icon>
            </popover-action-button>
            <popover-action-button
              v-if="showRemoveRelationship"
              label="Remove Relationship"
              @click.native="removeRelationshipClick(person, false)"
              v-close-popper="true"
            >
              <close-thick-icon :size="20"></close-thick-icon>
            </popover-action-button>
          </div>
        </v-dropdown>
      </div>
    </div>

    <div v-if="metaInfoLabel" class="meta-info text-sm">
      <div class="label">{{ metaInfoLabel }}</div>
      <div>
        <span>{{ metaInfoText[0] }}</span>
        <span class="separator" v-if="metaInfoText[0] && metaInfoText[1]"> • </span>
        <span>{{ metaInfoText[1] }}</span>
      </div>
    </div>

    <div v-if="children && children.length" class="children-info">
      <div class="label text-sm">Children</div>
      <div class="person-info child-info" v-for="child in children">
        <div class="profile-picture">
          <img v-if="child.profile_picture" :src="child.profile_picture" />
          <person-icon-colored v-else></person-icon-colored>
        </div>
        <div>
          <div class="person-name text-md text-lg-mobile">
            <router-link :to="getProfileRoute(child)">{{ getName(child) }}</router-link>
          </div>
        </div>
        <div class="context-menu">
          <v-dropdown :auto-hide="true" class="context-menu-popover">
            <div v-tooltip="{content: 'More Options'}" class="action-wrapper">
              <dots-vertical-icon :size="20" title=""></dots-vertical-icon>
            </div>
            <div slot="popper" class="popover-content">
              <popover-action-button label="View Profile" @click.native="viewProfile(child)">
                <account-icon :size="20" />
              </popover-action-button>
              <popover-action-button label="View in Tree" @click.native="viewInTree(child)">
                <tree-icon :size="20" />
              </popover-action-button>
              <popover-action-button
                v-if="showRemoveRelationship"
                label="Remove Relationship"
                @click.native="removeRelationshipClick(child, true)"
                v-close-popper="true"
              >
                <close-thick-icon :size="20"></close-thick-icon>
              </popover-action-button>
            </div>
          </v-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {formatApproximateDate, getLifetimeYears} from '@common/utils/utils';
import AccountIcon from 'vue-material-design-icons/Account';
import CloseThickIcon from 'vue-material-design-icons/CloseThick';
import DotsVerticalIcon from 'vue-material-design-icons/DotsVertical';
import PencilIcon from 'vue-material-design-icons/Pencil';
import PlusIcon from 'vue-material-design-icons/Plus';
import TreeIcon from 'vue-material-design-icons/Sitemap';
import SwapIcon from 'vue-material-design-icons/SwapHorizontal';

import PersonIconColored from '@/base/icons/person/PersonIconColored';

import PopoverActionButton from '@/components/common/modals/popoverActionButton';

import {UNKNOWN_NAME} from '@/components/modules/familyTree/constants';

export default {
  props: {
    person: Object,
    profilePicture: String,
    spouseMeta: Object,
    metaEditable: {type: Boolean, default: false},
    showAddChild: {type: Boolean, default: false},
    showRemoveRelationship: {type: Boolean, default: false},
    showChangeParents: {type: Boolean, default: false},
    children: Array,
  },
  computed: {
    years() {
      return getLifetimeYears(this.person.birth_date, this.person.death_date, this.person.is_deceased);
    },
    marriagePlaceText() {
      return this.spouseMeta && this.spouseMeta.marriage_place && this.spouseMeta.marriage_place.display_text;
    },
    marriageDateText() {
      return this.spouseMeta && this.spouseMeta.marriage_date
        ? formatApproximateDate(this.spouseMeta.marriage_date)
        : '';
    },
    divorcePlaceText() {
      return this.spouseMeta && this.spouseMeta.divorce_place && this.spouseMeta.divorce_place.display_text;
    },
    divorceDateText() {
      return this.spouseMeta && this.spouseMeta.divorce_date ? formatApproximateDate(this.spouseMeta.divorce_date) : '';
    },
    metaInfoText() {
      if (this.divorcePlaceText || this.divorceDateText) {
        return [this.divorceDateText, this.divorcePlaceText];
      }
      if (this.marriagePlaceText || this.marriageDateText) {
        return [this.marriageDateText, this.marriagePlaceText];
      }
    },
    metaInfoLabel() {
      if (this.divorcePlaceText || this.divorceDateText) {
        return 'Divorced';
      }
      if (this.marriagePlaceText || this.marriageDateText) {
        return 'Married';
      }
    },
    profileRoute() {
      return this.getProfileRoute(this.person);
    },
  },
  methods: {
    viewProfile(person) {
      this.$router.push(this.getProfileRoute(person));
    },
    viewInTree(person) {
      this.$router.push({
        name: 'familytree-details',
        params: {id: this.$route.params.id},
        query: {focus_person_id: person.object_id},
      });
    },
    editRelationship() {
      this.$emit('edit-spouse-meta', {person: this.person, meta: this.spouseMeta});
    },
    addChildClick() {
      this.$emit('add-child', {person: this.person});
    },
    removeRelationshipClick(person, isChild) {
      this.$emit('remove-relationship', {person, isChild});
    },
    changeParentsClick() {
      this.$emit('change-parents', {person: this.person});
    },
    getName(person) {
      return person.full_name || UNKNOWN_NAME;
    },
    getProfileRoute(person) {
      return {
        name: 'familytree-profile-details',
        params: {id: this.$route.params.id, personId: person.object_id},
        query: {tab: 'details'},
      };
    },
  },
  components: {
    PersonIconColored,
    DotsVerticalIcon,
    TreeIcon,
    AccountIcon,
    PopoverActionButton,
    PencilIcon,
    PlusIcon,
    SwapIcon,
    CloseThickIcon,
  },
  name: 'RelativeItem',
};
</script>

<style lang="scss" scoped>
.relative-item {
  border-radius: 4px;
  border: 1px solid $neutral-200;
  padding: 12px 16px;
  background: white;
  display: flex;
  flex-direction: column;

  .person-info {
    display: flex;
    column-gap: 12px;
    align-items: center;
    .person-name {
      word-break: break-word;
      font-weight: 500;
    }
  }

  .profile-picture {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    flex-grow: 0;
    border-radius: 4px;
    overflow: hidden;

    &::v-deep svg {
      width: 38px;
      height: 38px;
    }
  }

  .sub-text {
    color: $neutral-500;
  }
  .separator {
    color: $neutral-400;
  }
  .divorced-badge {
    background: $primary-50;
    color: $primary-400;
    font-weight: 800;
    padding: 0 5px;
    border-radius: 4px;
  }
  span + .divorced-badge {
    margin-left: 8px;
  }

  .meta-info {
    border-top: 1px solid $neutral-200;
    padding-top: 8px;
    margin-top: 12px;
    .label {
      color: $neutral-500;
    }
  }
  .children-info {
    margin-top: 16px;

    .label {
      margin-bottom: 8px;
      color: $neutral-500;
    }
    .child-info + .child-info {
      margin-top: 12px;
      padding-top: 12px;
      border-top: 1px solid $neutral-200;
    }
  }

  .context-menu {
    margin-left: auto;
    display: flex;
    .context-menu-popover {
      display: flex;
      cursor: pointer;
      color: $neutral-500;

      .action-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &::v-deep .option {
      display: flex;
      align-items: center;
    }
    &::v-deep .trigger {
      height: 20px;
    }
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
  }
}
</style>
