<template>
  <transition mode="out-in" name="info-bar-slide-bottom">
    <div
      v-if="showViewStep"
      key="stepview"
      class="person-quick-view"
      @dragenter.stop.prevent
      @dragover.stop.prevent="onDragover"
      @dragleave.self="onDragleave"
      @drop.stop.prevent="onDrop"
      v-touch:swipe.bottom="onSwipeBottom"
    >
      <close-button @click="$emit('close')"></close-button>
      <transition name="fade">
        <drag-drop-prompt v-show="isDragover"></drag-drop-prompt>
      </transition>
      <div class="heading">
        <div class="picture-container">
          <img
            :src="profilePictureAttachment"
            v-if="profilePictureAttachment"
            class="profile-picture"
            @click="profilePictureClick"
          />
          <div class="loading-profile-picture" v-else-if="showProfilePictureLoading"></div>
          <person-icon-colored v-else :gender="person.gender" @click.native="personIconClick"></person-icon-colored>
        </div>
        <div class="name-container">
          <div class="heading-4 heading-5-mobile" v-if="personPrimaryFullnameEn">{{ personPrimaryFullnameEn }}</div>
          <div class="heading-4 heading-5-mobile" v-if="personPrimaryFullnameCn">{{ personPrimaryFullnameCn }}</div>
          <div class="heading-4 heading-5-mobile" v-if="!personPrimaryFullnameEn && !personPrimaryFullnameCn">
            {{ unknownName }}
          </div>
          <div class="strapline" v-if="livingStatus">{{ livingStatus }}</div>
        </div>
      </div>
      <person-quick-view-actions
        :person-name="personPrimaryFullnameEn || personPrimaryFullnameCn || 'this person'"
        :sections="contextMenuSections"
        :relatives-loading="$store.getters.familyTreePersonRelativesLoadingState"
        :can-add-parents="canAddParents"
        :hints-count="hintsCount"
        @click-full-profile="fullProfileHandler"
        @click-full-profile-edit="fullProfileEditHandler"
        @click-quick-edit="quickEditHandler"
        @click-view-tree="viewTreeHandler"
        @click-view-lineage="viewLineageHandler"
        @click-delete="deleteHandler"
        @click-add-parent="addParentHandler"
        @click-add-spouse="addSpouseHandler"
        @click-add-sibling="addSiblingHandler"
        @click-add-child="addChildHandler"
        @click-show-hints="showHintsHandler"
      ></person-quick-view-actions>
      <div class="details">
        <div class="section">
          <person-photos-container
            ref="photos"
            :add-allowed="addPhotosAllowed"
            :person-id="person.id"
            :person-name-en="personPrimaryFullnameEn"
            :person-name-cn="personPrimaryFullnameCn"
            :explicit-loading="showExplicitPhotosLoading"
            :display-photos-count-mobile="3"
            @files-selected="filesSelected"
            @file-uploaded="fileUploaded"
          >
            <div class="label no-margin" slot="title">Photos</div>
          </person-photos-container>
        </div>

        <div class="section mobile-only spacing"></div>

        <person-quick-view-actions
          class="mobile-only"
          :person-name="personPrimaryFullnameEn || personPrimaryFullnameCn || 'this person'"
          :sections="contextMenuSections"
          :hints-count="hintsCount"
          :enable-hotkeys="false"
          @click-full-profile="fullProfileHandler"
          @click-full-profile-edit="fullProfileEditHandler"
          @click-quick-edit="quickEditHandler"
          @click-show-hints="showHintsHandler"
          @open-more-actions-modal="openMoreActionsModal"
          @open-add-relative-modal="openAddRelativeModal"
        ></person-quick-view-actions>

        <div class="flex-row section" v-if="person.notes_display">
          <div>
            <div class="label">Biography</div>
            <div v-html="person.notes_display"></div>
          </div>
        </div>
        <div class="flex-row" v-for="(names, typeDisplay) in firstNamesByType">
          <div class="label">{{ typeDisplay }}</div>
          <div class="value flex-column name">
            <div v-for="(name, index) in names" :key="index">{{ name }}</div>
          </div>
        </div>

        <div class="flex-row" v-for="(names, typeDisplay) in surnamesByType">
          <div class="label">{{ typeDisplay }}</div>
          <div class="value flex-column name">
            <div v-for="(name, index) in names" :key="index">
              <router-link v-if="isChineseText(name)" :to="getTreeClanDetailRoute(person.family_tree_id, name)">{{
                name
              }}</router-link>
              <span v-else>{{ name }}</span>
            </div>
          </div>
        </div>

        <div class="flex-row">
          <div class="label">Birth</div>
          <date-location-display
            class="value value-place"
            v-if="person.birth_date || person.birth_location"
            :date="person.birth_date"
            :location="person.birth_location"
          ></date-location-display>
          <div class="value" v-else>
            <tooltip-interactive
              placement="left"
              :shown="addBirthTooltipShown"
              :heading="addBirthTooltip.heading"
              :text="addBirthTooltip.text"
              :button-label="addBirthTooltip.buttonLabel"
              :delay="{show: 500}"
              @button-click="onBirthTooltipButtonClick"
              @close="saveViewedBirthTooltip"
            >
              <div>Unknown</div>
            </tooltip-interactive>
          </div>
        </div>

        <div class="flex-row" v-if="person.death_date || person.death_location">
          <div class="label">Death</div>
          <date-location-display
            class="value value-place"
            v-if="person.death_date || person.death_location"
            :date="person.death_date"
            :location="person.death_location"
          ></date-location-display>
          <div class="value" v-else>Unknown</div>
        </div>

        <div class="flex-row" v-if="person.generation_number">
          <div class="label">Generation #</div>
          <div class="value">{{ person.generation_number }}</div>
        </div>
        <div class="flex-row"><a @click="fullProfileHandler">View more on full profile</a></div>
      </div>
    </div>
    <quick-more-actions-modal
      v-else-if="showMoreStep"
      key="stepmore"
      :person-name="primaryName"
      :show-view-tree="showViewTree"
      :show-view-lineage="showViewLineage"
      :removable="removable"
      :view-tree-handler="viewTreeHandler"
      :view-lineage-handler="viewLineageHandler"
      :delete-handler="deleteHandler"
      @close="$emit('close')"
    ></quick-more-actions-modal>
    <quick-add-relative-modal
      v-else-if="showRelativeStep"
      key="steprelative"
      :person-name="primaryName"
      :person-id="person.object_id"
      :add-parent-handler="addParentHandler"
      :add-spouse-handler="addSpouseHandler"
      :add-sibling-handler="addSiblingHandler"
      :add-child-handler="addChildHandler"
      @close="$emit('close')"
    ></quick-add-relative-modal>
  </transition>
</template>

<script>
import CloseButton from '@common/elements/buttons/closeButton';
import DragDropPrompt from '@common/elements/layouts/DragDropPrompt';
import TooltipInteractive from '@common/elements/layouts/TooltipInteractive';
import {getLifetimeYears, isChineseText} from '@common/utils/utils';
import {getFullNameCn, getFullNameEn} from '@common/utils/utils.names';
import {getTreeClanDetailRoute} from '@common/utils/utils.routes';
import {mapGetters} from 'vuex';

import PersonIconColored from '@/base/icons/person/PersonIconColored';

import DateLocationDisplay from '@/components/common/tree/dateLocationDisplay';

import {
  CARD_FULL_PROFILE_HELPER_KEY,
  CONTEXT_MENU_SECTIONS,
  PROFILE_ADD_BIRTH_HELPER_KEY,
  QUICK_VIEW_HELPER_KEY,
  UNKNOWN_NAME,
} from '@/components/modules/familyTree/constants';
import PersonPhotosContainer from '@/components/modules/familyTree/tree/modals/personPhotosContainer';
import QuickAddRelativeModal from '@/components/modules/familyTree/tree/modals/quick/QuickAddRelativeModal';
import QuickMoreActionsModal from '@/components/modules/familyTree/tree/modals/quick/QuickMoreActionsModal';

import PersonQuickViewActions from './PersonQuickViewActions';
import personQuickViewMixin from './personQuickViewMixin';

const STEP_VIEW = 'stepview';
const STEP_MORE = 'stepmore';
const STEP_RELATIVE = 'steprelative';

export default {
  mixins: [personQuickViewMixin],
  props: {
    person: Object,
    addPhotosAllowed: Boolean,
    showViewTree: Boolean,
    showViewLineage: Boolean,
    removable: Boolean,
    contextMenuSections: Array,
    hintsCount: Number,
    fullProfileHandler: Function,
    fullProfileEditHandler: Function,
    fullProfileEditWithFocusHandler: Function,
    quickEditHandler: Function,
    viewTreeHandler: Function,
    viewLineageHandler: Function,
    deleteHandler: Function,
    addParentHandler: Function,
    addSpouseHandler: Function,
    addSiblingHandler: Function,
    addChildHandler: Function,
    showHintsHandler: Function,
  },
  data() {
    return {
      isDragover: false,
      shownModalViewStep: STEP_VIEW,
    };
  },
  computed: {
    ...mapGetters(['familyTreePersonsByIdState', 'reviewedItemsState']),
    livingStatus() {
      return getLifetimeYears(this.person.birth_date, this.person.death_date, this.person.is_deceased);
    },
    profilePicture() {
      const person = this.familyTreePersonsByIdState[this.person.id];
      return person && person.profile_picture && person.profile_picture;
    },
    profilePictureAttachment() {
      return this.profilePicture && this.profilePicture.attachment;
    },
    firstNamesByType() {
      let byType = {};
      for (let firstName of this.person.first_names || []) {
        const typeDisplay = firstName.type_display || 'First Name';
        byType[typeDisplay] = byType[typeDisplay] || [];
        byType[typeDisplay].push(firstName.value);
      }
      return byType;
    },
    surnamesByType() {
      let byType = {};
      for (let surname of this.person.surnames || []) {
        const typeDisplay = surname.type_display || 'Surname';
        byType[typeDisplay] = byType[typeDisplay] || [];
        byType[typeDisplay].push(surname.value);
      }
      return byType;
    },
    primaryFirstName() {
      const names = this.person.first_names;
      return names && names[0] && names[0].value ? names[0].value : UNKNOWN_NAME;
    },
    primaryName() {
      return this.personPrimaryFullnameEn || this.personPrimaryFullnameCn || UNKNOWN_NAME;
    },
    personPrimaryFullnameEn() {
      return getFullNameEn(this.person.first_names || [], this.person.surnames || []);
    },
    personPrimaryFullnameCn() {
      return getFullNameCn(this.person.first_names || [], this.person.surnames || []);
    },
    unknownName() {
      return this.person.is_current_user ? 'You' : UNKNOWN_NAME;
    },
    personParents() {
      return this.$store.getters.getFamilyTreePersonRelativesState(this.person.object_id).parents || [];
    },
    canAddParents() {
      return this.personParents.length !== 2;
    },
    showExplicitPhotosLoading() {
      return Boolean(this.profilePictureAttachment);
    },
    addBirthTooltip() {
      return {
        heading: `Do you know ${this.primaryFirstName}’s birth place?`,
        text: 'Adding birth details helps to automatically match records.',
        buttonLabel: 'Add Now',
      };
    },
    addBirthTooltipShown() {
      return (
        this.isDesktop &&
        !this.reviewedItemsState.includes(PROFILE_ADD_BIRTH_HELPER_KEY) &&
        this.reviewedItemsState.includes(QUICK_VIEW_HELPER_KEY) &&
        this.reviewedItemsState.includes(CARD_FULL_PROFILE_HELPER_KEY) &&
        this.person.hierarchy_level === 98 &&
        this.contextMenuSections.includes(CONTEXT_MENU_SECTIONS.FULL_PROFILE)
      );
    },
    isDesktop() {
      return this.$store.getters.windowWidthState > this.$breakpoints.mobile;
    },
    showViewStep() {
      return this.isDesktop || this.shownModalViewStep === STEP_VIEW;
    },
    showMoreStep() {
      return !this.isDesktop && this.shownModalViewStep === STEP_MORE;
    },
    showRelativeStep() {
      return !this.isDesktop && this.shownModalViewStep === STEP_RELATIVE;
    },
  },
  methods: {
    getTreeClanDetailRoute,
    isChineseText,
    openMoreActionsModal() {
      this.shownModalViewStep = STEP_MORE;
    },
    openAddRelativeModal() {
      this.shownModalViewStep = STEP_RELATIVE;
    },
    onDragover(e) {
      this.isDragover = true;
    },
    onDragleave(e) {
      this.isDragover = false;
    },
    onDrop(e) {
      this.isDragover = false;
      let fileList = e.dataTransfer.files;
      if (fileList.length > 0) {
        this.$refs.photos.filesSelected(fileList);
      }
    },
    onSwipeBottom() {
      this.$emit('close');
    },
    onBirthTooltipButtonClick() {
      this.saveViewedBirthTooltip();
      this.fullProfileEditWithFocusHandler('birth');
    },
    saveViewedBirthTooltip() {
      this.$store.commit('addReviewedItemState', PROFILE_ADD_BIRTH_HELPER_KEY);
    },
  },
  components: {
    TooltipInteractive,
    DragDropPrompt,
    PersonPhotosContainer,
    CloseButton,
    PersonIconColored,
    DateLocationDisplay,
    PersonQuickViewActions,
    QuickAddRelativeModal,
    QuickMoreActionsModal,
  },
  name: 'PersonQuickView',
};
</script>

<style lang="scss" scoped>
.person-quick-view {
  padding: 30px;
  min-height: 100%;
  box-sizing: border-box;
  position: relative;

  .heading {
    display: flex;
    padding-bottom: 20px;

    .name-container {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      word-break: break-word;
      h4 {
        margin: 0;
      }
    }

    .strapline {
      color: $text-alternate-color;
      margin-top: 8px;
    }
  }

  .mobile-only {
    display: none;
  }

  .person-photos-container {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &::v-deep .label {
    width: 140px;
    color: $mcr-grey;
    margin-right: 15px;
    flex-shrink: 0;
  }

  .flex-row {
    padding: 10px 0;

    .value-place {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      &::v-deep .place-wrapper {
        display: flex;
        align-items: flex-start;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .section {
    border-bottom: 1px solid $border-color-light;
    margin-bottom: 10px;
    .label:not(.no-margin) {
      margin-bottom: 10px;
    }
  }

  .picture-container {
    width: 136px;
    height: 136px;
    flex-shrink: 0;
    cursor: pointer;
    border-radius: 4px;
    overflow: hidden;
  }
  .loading-profile-picture {
    width: 100%;
    height: 100%;
    background-color: $photo-border-color;
    border-radius: 4px;
    margin-bottom: 5px;
  }

  @media only screen and (min-width: $quick-sidebar-breakpoint) {
    .heading {
      .name-container {
        max-width: 210px;
      }
    }
    width: $quick-sidebar-width;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    padding: 0;

    .close-btn,
    .person-quick-view-actions,
    .details .flex-row {
      display: none;
    }

    .heading {
      padding: 16px 16px 0;
      border-bottom: 0;
      .strapline {
        margin: 0;
        color: $neutral-500;
      }
    }
    .label {
      color: $neutral-600;
    }
    .mobile-only {
      display: block;
    }
    .section {
      border-bottom: 1px solid $neutral-200;
      margin-bottom: 0;
    }
    .section.spacing {
      height: 16px;
      background: $neutral-100;
    }
    .picture-container {
      width: 58px;
      height: 58px;
      &::v-deep .person-icon {
        width: 40px;
      }
    }
    .person-photos-container {
      border-top: 1px solid $neutral-200;
      padding: 16px;
      margin-top: 16px;

      &::v-deep {
        .heading {
          padding: 0;
          border-bottom: none;

          .add-button {
            color: $neutral-500;
          }
        }
        .photos {
          margin-top: 8px;
        }
        .view-all-link {
          margin-top: 12px;
          a {
            color: $neutral-500;
          }
        }
        .image-preview-gallery {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }
}
</style>
