<template>
  <div
    class="person-photos-container"
    v-if="addAllowed || (familyTreePersonPhotosLoadingState && explicitLoading) || displayPhotos.length"
  >
    <div class="heading">
      <div v-if="addAllowed && !familyTreePersonPhotosLoadingState" class="add-button" @click="addPhotos">+ Upload</div>
      <bounce-loading v-if="addAllowed && familyTreePersonPhotosLoadingState" class="add-button"></bounce-loading>
      <slot name="title">
        <h5>{{ title }}</h5>
      </slot>
      <input type="file" multiple class="files-input" :ref="filesInputRef" @change="filesSelectedHandler" />
    </div>
    <div class="photos image-preview-gallery" v-if="displayPhotos.length">
      <file-preview
        v-for="photo in displayPhotos"
        :key="photo._id || photo.object_id"
        :src="photo.uploadingPreviewSrc || photo.attachment"
        :filename="photo.filename"
        :has-error="photo.error"
        :is-uploading="photo.uploading"
        :upload-progress="photo.progress"
        :show-label="false"
        @click.native="showGallery(photo)"
      ></file-preview>
    </div>
    <div v-else-if="familyTreePersonPhotosLoadingState && explicitLoading" class="photos image-preview-gallery">
      <file-preview v-for="i in loadingItems" :key="i" class="photo-loading"></file-preview>
    </div>
    <div v-if="showViewAllLink" class="view-all-link">
      <router-link :to="libraryRoute">View all in Photos & Files</router-link>
    </div>
  </div>
</template>

<script>
import BounceLoading from '@common/elements/loading-indicators/bounceLoading';
import {mapGetters} from 'vuex';

import consts from '@/base/utils/consts';

import filePreview from '@/components/common/filePreview';

export default {
  components: {filePreview, BounceLoading},
  props: {
    personId: Number,
    personNameEn: String,
    personNameCn: String,
    addAllowed: Boolean,
    explicitLoading: {type: Boolean, default: true},
    displayPhotosCountDesktop: {type: Number, default: 8},
    displayPhotosCountMobile: {type: Number, default: 4},
    allowViewAllLink: {type: Boolean, default: true},
  },
  data() {
    return {
      filesInputRef: 'files-input',
      maxFileSizeMB: consts.maxFileSizeMB,
      maxFileSize: consts.maxFileSizeMB * 1024 * 1024,
      allowedFileExtensions: [...consts.imageExtensions, ...consts.videoExtensions],
      addPhotosCaller: null,
    };
  },
  computed: {
    ...mapGetters([
      'familyTreePhotosByPersonIdState',
      'familyTreePersonPhotosLoadingState',
      'isUserOwnerFamilyTreeState',
    ]),
    title() {
      return 'Photos';
    },
    photos() {
      return this.familyTreePhotosByPersonIdState[this.personId] || [];
    },
    photosCount() {
      return this.photos.length;
    },
    galleryOverlayItems() {
      return this.displayPhotos.map(p => {
        return {
          ...p,
          src: p.attachment,
          ...this.photoMetadata,
        };
      });
    },
    displayPhotosCount() {
      return this.isDesktop ? this.displayPhotosCountDesktop : this.displayPhotosCountMobile;
    },
    loadingItems() {
      const generateRange = n => Array.from({length: n}, (_, i) => i + 1);
      return this.isDesktop
        ? generateRange(this.displayPhotosCountDesktop)
        : generateRange(this.displayPhotosCountMobile);
    },
    displayPhotos() {
      return this.photos.slice(0, this.displayPhotosCount);
    },
    isDesktop() {
      return this.$store.getters.windowWidthState > this.$breakpoints.tablet;
    },
    libraryRoute() {
      return {
        name: 'familytree-profile-details',
        params: {id: this.$route.params.id, personId: this.personId},
        query: {tab: 'library'},
      };
    },
    photoMetadata() {
      return {
        editable: this.addAllowed,
        personId: this.personId,
        personNameEn: this.personNameEn,
        personNameCn: this.personNameCn,
      };
    },
    showViewAllLink() {
      return this.allowViewAllLink && this.photosCount > 3 && this.isUserOwnerFamilyTreeState;
    },
  },
  methods: {
    initializeGallery() {
      this.$store.commit('setGalleryItemsState', this.galleryOverlayItems);
    },
    addPhotos(calledFrom) {
      this.$refs[this.filesInputRef].click();
      this.addPhotosCaller = calledFrom;
    },
    filesSelectedHandler(event) {
      this.filesSelected(event.target.files);
    },
    filesSelected(files) {
      for (let file of files) {
        if (file.size > this.maxFileSize) {
          return this.handleFilesSelectError(`File max size: ${this.maxFileSizeMB}MB`);
        }
        const fileExtension = file.name.split('.').pop();
        if (!this.allowedFileExtensions.includes(fileExtension.toLowerCase())) {
          return this.handleFilesSelectError(`Allowed file extensions: ${this.allowedFileExtensions}`);
        }
        this.$emit('files-selected', {initiator: this.addPhotosCaller});
        this.$store
          .dispatch('uploadFamilyTreePersonPhotoAction', {personId: this.personId, file})
          .then(response => {
            this.$emit('file-uploaded', response);
          })
          .catch(error => {
            const message = error && error.response && error.response.data && error.response.data.error;
            this.handleFilesSelectError(message || 'Error. Please try again later.');
          })
          .finally(() => {
            this.initializeGallery();
          });
      }
      this.clearInputFiles();
    },
    clearInputFiles() {
      this.$refs[this.filesInputRef].value = '';
    },
    handleFilesSelectError(errorText) {
      this.clearInputFiles();
      this.$toasted.error(errorText);
    },
    showGallery(photo) {
      if (!photo.uploading && !photo.error) {
        this.initializeGallery();
        this.$store.dispatch('displayGalleryAction', photo._id);
      }
    },
  },
  name: 'personPhotosContainer',
};
</script>

<style scoped lang="scss">
@import './tree-modal.scss';

.person-photos-container {
  .add-button {
    cursor: pointer;
    float: right;
    color: $neutral-500;
    &:hover {
      color: $link-color;
    }
  }
  .photos {
    margin-top: 10px;
  }

  .files-input {
    display: none;
  }

  .photo-loading {
    &::v-deep .file-preview-content {
      display: none;
    }
  }
  .bounce-loading::v-deep > div {
    background-color: $mcr-light-grey;
  }

  .view-all-link {
    margin-top: 10px;
    a {
      color: $mcr-grey;
      &:hover {
        color: $mcr-red;
        text-decoration: none;
      }
    }
  }
}
</style>
