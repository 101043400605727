<template>
  <div class="onboarding-wizard">
    <div class="heading">
      <div class="title">
        <progress-circle :quarters="progressCircleQuarters"></progress-circle>
        <div class="text-lg bold">Getting started</div>
      </div>
      <close-icon class="close-icon" @click="closeClick"></close-icon>
    </div>
    <div class="steps-list">
      <transition-group name="step-fade" tag="div">
        <template v-for="step in stepsList">
          <div v-if="step.isDelimiter" class="steps-list-title text-sm" :key="step.key">{{ step.text }}</div>

          <wizard-step
            v-else
            :key="step.key"
            :data-key="step.key"
            :is-completed="step.isCompleted"
            :title="step.key === currentStepKey ? activeTitles[step.key] || step.title : step.title"
            :is-current="step.key === currentStepKey"
            :content="step.content"
            :image-src="step.imageSrc"
            :image-caption="step.imageCaption"
            :no-mobile-image="step.noMobileImage"
            :tip="step.tip"
            ref="stepRefs"
            @animation-end="onStepAnimationEnd(step)"
          >
          </wizard-step>
        </template>
      </transition-group>

      <transition name="fade-in-delayed">
        <div v-if="!hasNextStep" class="text-sm message-done">
          <div class="heading-1">🎉</div>
          <div class="heading-5">Great work so far!</div>
          <div class="text-md">
            You’re all set to continue your ancestry journey on your own. Keep expanding your family tree and increase
            the chance of getting hints.
          </div>
          <div>
            <mcr-button class="white small close-button" @click="$emit('close')"><span>Continue</span></mcr-button>
          </div>
        </div>
      </transition>
    </div>

    <div
      style="display: flex; flex-direction: column; padding: 20px; position: fixed; bottom: 20px"
      v-if="$store.getters.userIsStaffState"
    >
      DEBUG:
      <button @click="setDoneDebug">Done current step (in background)</button>
      <br />
      <button @click="clearState">Reset wizard state</button>
    </div>
  </div>
</template>

<script>
import mcrButton from '@common/elements/buttons/mcrButton';
import ProgressCircle from '@common/elements/icons/ProgressCircle';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import CloseIcon from 'vue-material-design-icons/Close';

import WizardStep from '@/components/modules/familyTree/tree/modals/wizard/WizardStep.vue';

export default {
  props: {
    treeId: String,
    savedState: Object,
    currentState: Object,
    isAutoOpen: Boolean,
  },
  mounted() {
    this.loadRefsList();
    this.setTreeFocus();
    this.trackWizardOpen();
    setTimeout(() => {
      this.syncStoreAndSavedState();
    }, 500);
  },
  data() {
    const steps = [
      this.savedState.stepAddParents,
      this.savedState.stepAddGrandparent,
      this.savedState.stepViewGrandparent,
      this.savedState.stepBirthGrandparent,
    ];
    const progressCircleQuarters = steps.filter(s => !!s).length;

    return {
      stepComponents: {},
      steps: [
        {
          key: 'stepAddParents',
          title: 'Add your parents',
          imageSrc: this.$getAsset('/assets/services/wizard/add-parents.png'),
          isCompleted: !!this.savedState.stepAddParents,
        },
        {
          key: 'stepAddGrandparent',
          title: 'Add a first grandparent',
          content:
            'Another way to add relatives is with <strong>quick view</strong>. Click on one of your parent’s cards and see for yourself!',
          tip: {
            title: 'Automatic record matching',
            content: 'Any ancestor added to your tree is automatically qualified for our hints system!',
          },
          isCompleted: !!this.savedState.stepAddGrandparent,
        },
        {
          key: 'stepViewGrandparent',
          title: 'Grandparent details',
          content: 'In <strong>quick view</strong>, you can also access the full details of a person. Try it out now.',
          imageSrc: this.$getAsset('/assets/services/wizard/view-grandparent-profile.png'),
          imageCaption: '<strong>TIP</strong>: Use <strong>P</strong> key shortcut while in quick view',
          noMobileImage: true,
          isCompleted: !!this.savedState.stepViewGrandparent,
        },
        {
          key: 'stepBirthGrandparent',
          title: 'Add birth details',
          content: 'Access <strong>quick edit</strong> by double-clicking on any person card.',
          tip: {
            title: 'Improve match quality',
            content:
              'Adding places and dates to person profiles will also boost the quality of your hinted record matches.',
          },
          isCompleted: !!this.savedState.stepBirthGrandparent,
        },
      ],
      progressCircleQuarters,
    };
  },
  computed: {
    hasNextStep() {
      return this.steps.some(s => !s.isCompleted);
    },
    stepsList() {
      if (!this.hasNextStep) {
        return [];
      }
      let steps = [];

      let sortedSteps = [...this.steps].sort((a, b) => a.isCompleted - b.isCompleted);
      let hasCompleted = false;
      for (let i = 0; i < sortedSteps.length; i++) {
        let step = sortedSteps[i];

        if (i === 0 && !step.isCompleted) {
          steps.push({isDelimiter: true, text: 'Up next:', key: 'up-next'});
        }
        if (step.isCompleted && !hasCompleted) {
          hasCompleted = true;
          steps.push({isDelimiter: true, text: 'Completed:', key: 'completed'});
        }
        steps.push(step);
      }

      return steps;
    },
    currentStepKey() {
      const curStep = this.steps.find(s => !s.isCompleted);
      return curStep ? curStep.key : null;
    },
    activeTitles() {
      return {
        stepViewGrandparent: `View ${this.grandparentName}\'s full profile`,
        stepBirthGrandparent: `Add birth details to ${this.grandparentName}`,
      };
    },
    grandparent() {
      const grandparentId = this.currentState.grandParentsIds ? this.currentState.grandParentsIds[0] : null;
      const grandParentFocus = grandparentId ? this.$store.getters.familyTreePersonsByIdState[grandparentId] : null;
      return grandParentFocus || null;
    },
    grandparentName() {
      return this.grandparent ? this.grandparent.full_name || 'your Grandparent' : 'your Grandparent';
    },
  },
  methods: {
    loadRefsList() {
      if (!this.$refs.stepRefs) {
        return;
      }
      for (let ref of this.$refs.stepRefs) {
        this.stepComponents[ref.$attrs['data-key']] = ref;
      }
    },
    syncStoreAndSavedState() {
      for (let step of this.steps) {
        if (!step.isCompleted && this.currentState[step.key]) {
          this.stepComponents[step.key].animate();
        }
      }
    },
    onStepAnimationEnd(step) {
      this.$set(step, 'isCompleted', true);
      this.progressCircleQuarters += 1;
      this.$store.commit('updateOnboardingWizardSavedState', {treeId: this.treeId, data: {[step.key]: true}});
      this.setTreeFocus();

      this.$nextTick(() => {
        if (!this.hasNextStep) {
          this.$store.commit('endOnboardingWizardState', {treeId: this.treeId});
        }
      });
    },
    setTreeFocus() {
      const grandparentSteps = ['stepViewGrandparent', 'stepBirthGrandparent'];
      if (this.currentStepKey && grandparentSteps.includes(this.currentStepKey) && this.grandparent) {
        this.$store.commit('setFamilyTreeFocusPersonIdState', this.grandparent.object_id);
      }
    },
    closeClick() {
      AnalyticsAmplitudeHandler.trackOnboardingWizardClosedEvent(this.getCurrentStepKey());
      this.$emit('close');
    },
    trackWizardOpen() {
      const type = this.isAutoOpen ? 'auto' : 'manual';
      const step = this.getCurrentStepKey();
      AnalyticsAmplitudeHandler.trackOnboardingWizardShownEvent(step, type);
    },
    getCurrentStepKey() {
      // returns by the current state instead of data, so that it works before the animations finish.
      if (!this.currentState.stepAddParents) {
        return 'stepAddParents';
      }
      if (!this.currentState.stepAddGrandparent) {
        return 'stepAddGrandparent';
      }
      if (!this.currentState.stepViewGrandparent) {
        return 'stepViewGrandparent';
      }
      if (!this.currentState.stepBirthGrandparent) {
        return 'stepBirthGrandparent';
      }
      return 'stepCompleted';
    },
    //DEBUG
    setDoneDebug(el) {
      for (let step of this.steps) {
        if (step.key === this.currentStepKey) {
          let actualState = this.currentState[this.treeId] || {};
          actualState[step.key] = true;
          this.$store.commit('updateOnboardingWizardState', {treeId: this.treeId, data: actualState});
          this.$toasted.success(step.key + ' marked as done');
        }
      }
    },
    clearState() {
      let empty = {
        showButtonTooltip: null,
        stepAddParents: false,
        stepAddGrandparent: false,
        stepViewGrandparent: false,
        stepBirthGrandparent: false,
      };
      this.$store.commit('updateOnboardingWizardSavedState', {treeId: this.treeId, data: empty});
      this.$store.commit('updateOnboardingWizardState', {treeId: this.treeId, data: empty});
      this.$toasted.success('wizard state reset');
    },
  },
  components: {WizardStep, ProgressCircle, CloseIcon, mcrButton},
  name: 'OnboardingWizard',
};
</script>

<style lang="scss" scoped>
.onboarding-wizard {
  .wizard-step {
    margin-bottom: 16px;
  }

  .heading {
    padding: 16px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $neutral-200;

    .title {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }

    .text-lg {
      color: $neutral-600;
    }

    .close-icon {
      cursor: pointer;
      color: $neutral-500;
    }
  }

  .steps-list {
    padding: 20px;

    .steps-list-title {
      margin-bottom: 8px;
      color: $neutral-500;
      text-transform: uppercase;
    }
    .message-done {
      transition: all 0.5s;
    }
  }
  .message-done {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    .heading-1 {
      margin-bottom: 16px;
    }
    .heading-5 {
      margin-bottom: 8px;
    }
    .text-md {
      margin-bottom: 16px;
    }
  }
  .wizard-step + .steps-list-title {
    margin-top: 20px;
  }

  .step-fade-move,
  .step-fade-enter-active,
  .step-fade-leave-active {
    transition: all 0.5s ease;
  }

  .step-fade-enter,
  .step-fade-enter-from,
  .step-fade-leave-to {
    opacity: 0;
  }

  .step-fade-leave-active {
    position: absolute;
  }

  .fade-in-delayed-enter,
  .fade-in-delayed-leave-to {
    opacity: 0;
  }

  .fade-in-delayed-enter-active,
  .fade-in-delayed-leave-active {
    transition: all 0.5s ease;
  }

  @media only screen and (min-width: $main-menu-breakpoint) {
    max-width: 400px;
  }
  @media only screen and (min-width: $breakpoint-mobile-se) {
    min-width: 400px;
  }

  @media only screen and (max-width: $main-menu-breakpoint) {
    .heading {
      padding: 16px;
    }
    .steps-list {
      padding: 20px 16px;
    }
  }
}
</style>
