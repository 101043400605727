<template>
  <svg v-if="viewBox" class="map-svg" :viewBox="viewBox" :style="svgStyle">
    <line
      v-for="connector in connectors"
      :key="connector.id"
      :x1="connector.x1"
      :y1="connector.y1"
      :x2="connector.x2"
      :y2="connector.y2"
      :stroke="lineColor"
      stroke-width="8"
      style="z-index: 9"
    />
    <rect
      style="z-index: 10"
      v-for="person of persons"
      :width="cardWidth"
      :height="cardHeight"
      :x="person.position.x"
      :y="person.position.y"
      :fill="getCardColor(person)"
      stroke-width="10"
      :stroke="lineColor"
    />
  </svg>
</template>

<script>
import {WIDTH, HEIGHT} from '@/components/modules/familyTree/constants';

export default {
  props: {
    persons: Array,
    connectors: Array,
    viewBox: String,
    fullWidth: Number,
    fullHeight: Number,
    wrapperStyle: Object,
    colorCodeGender: {type: Boolean, default: true},
  },
  data() {
    return {
      cardWidth: WIDTH,
      cardHeight: HEIGHT,
      maleColor: '#b0e0e6',
      femaleColor: '#ffc0cb',
      neutralColor: '#eceae8',
      ghostMaleColor: '#ece8e4',
      ghostFemaleColor: '#ece8e4',
      lineColor: '#6B7280',
    };
  },
  computed: {
    svgStyle() {
      return {
        'z-index': '11',
        width: this.fullWidth + 'px',
        height: this.fullHeight + 'px',
        ...this.wrapperStyle,
      };
    },
  },
  methods: {
    getCardColor(person) {
      if (person.is_ghost) {
        if (person.is_male) {
          return this.ghostMaleColor;
        }
        return this.ghostFemaleColor;
      }
      if (!this.colorCodeGender) {
        return this.neutralColor;
      }
      if (person.is_male) {
        return this.maleColor;
      }
      if (person.is_female) {
        return this.femaleColor;
      }
      return this.neutralColor;
    },
  },
  components: {},
  name: 'family-tree-simple-svg',
};
</script>

<style scoped lang="scss">
.map-svg {
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  user-drag: none;
  pointer-events: none;
  position: absolute;
}
</style>
