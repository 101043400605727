<template>
  <div class="wizard-step" :class="classes">
    <div class="heading" :class="{'text-md': !isCurrent, 'text-lg': isCurrent}">
      <animated-checkmark
        ref="checkmark"
        :is-completed-state="isCompleted"
        :is-active="isCurrent"
        @complete="onAnimationEnd"
      ></animated-checkmark>
      <span class="title">{{ title }}</span>
    </div>
    <transition name="step-content-fade">
      <div v-show="isCurrent" ref="content" class="content text-md" :class="{'no-mobile-image': noMobileImage}">
        <div v-if="content" v-html="content" class="content-text"></div>
        <div v-if="imageSrc" class="image-wrapper"><img :src="imageSrc" /></div>
        <div v-if="imageCaption" class="image-caption text-sm" v-html="imageCaption"></div>
        <div v-if="tip" class="tip text-sm">
          <div class="bold tip-title">{{ tip.title }}</div>
          <div>{{ tip.content }}</div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import AnimatedCheckmark from '@common/elements/icons/AnimatedCheckmark.vue';

export default {
  props: {
    title: String,
    isCompleted: {
      type: Boolean,
      default: false,
    },
    isCurrent: {
      type: Boolean,
      default: false,
    },
    content: String,
    imageSrc: String,
    imageCaption: String,
    noMobileImage: {type: Boolean, default: false},
    tip: Object,
  },
  computed: {
    classes() {
      return {
        'is-completed': this.isCompleted,
        'is-current': this.isCurrent,
      };
    },
  },
  methods: {
    animate() {
      // this.$refs.content.style.height = `${this.$refs.content.offsetHeight}px`;
      this.$refs.checkmark.toDone();
    },
    onAnimationEnd() {
      this.$emit('animation-end');
    },
  },
  components: {AnimatedCheckmark},
  name: 'WizardStep',
};
</script>

<style scoped lang="scss">
.wizard-step::v-deep {
  overflow: hidden;
  border: 1px solid $neutral-200;
  border-radius: 4px;

  .heading {
    display: flex;
    align-items: center;
    padding: 12px 16px;

    .title {
      margin-left: 8px;
    }
  }

  .content {
    padding: 0 20px 20px;
    max-height: 300px;
  }

  .image-wrapper {
    display: flex;
  }
  .image-caption {
    margin-top: 8px;
    text-align: center;
  }
  .content-text + .image-wrapper {
    margin-top: 20px;
  }
  .tip {
    padding: 12px;
    background: $neutral-100;
    color: $neutral-600;
    border-radius: 4px;
    margin-top: 20px;
    .tip-title {
      margin-bottom: 4px;
    }
  }

  @media only screen and (max-width: $main-menu-breakpoint) {
    .content.no-mobile-image {
      .image-wrapper,
      .image-caption {
        display: none;
      }
    }
  }
}

.wizard-step.is-current {
  border: 1px solid $neutral-300;
  .heading {
    font-weight: bold;
    align-items: flex-start;
  }
}

.wizard-step:not(.is-current) {
  background-color: $neutral-100;
  color: $neutral-500;

  .heading {
    font-weight: 400;
  }

  .step-content-fade-enter-active,
  .step-content-fade-leave-active {
    transition: all 0.25s ease;
  }

  .step-content-fade-enter-from,
  .step-content-fade-leave-to {
    opacity: 0;
    max-height: 0;
  }
}
</style>
